import { useEffect, useState } from "react";
import classes from "./PagosEstudiante.module.css";
import useToken from "../login/useToken";
import {
  EnviaDatosAServicio,
  FechaMMMDeString,
  FormateaCurrency,
} from "../../Funciones/Funciones";
import FiltroPagosEstudiante from "./PagosEstudianteFiltroMenu";
import Backdrop from "../layout/backdrop";
import NuevoIngreso from "./IngresoNuevo";
import { Slide, toast } from "react-toastify";
import ReciboPago from "./ReciboPago";

function PagosEstudiante(props) {
  const [isLoading, setIsLoading] = useState(false);
  const [pagosEstudianteLoaded, setPagosEstudianteLoaded] = useState([]);
  const [formasPago, setFormasPago] = useState([]);
  const [nombre, setNombre] = useState("");
  const [iconoOrdena, setIconoOrdena] = useState("fas fa-sort-amount-up-alt");
  const [posFiltroPagos, setPosFiltroPagos] = useState({});
  const [muestraFiltroPagos, setMuestraFiltroPagos] = useState(false);
  const [filtroPagos, setFiltroPagos] = useState({
    Renta: true,
    DepGar: true,
    Llaves: true,
    FechaIni: "",
    FechaFin: "",
  });
  const [datosEdicionPago, setDatosEdicionPago] = useState([]);
  const [muestraEdicionPago, setMuestraEdicionPago] = useState(false);
  const [muestraRecibo, setMuestraRecibo] = useState(false);
  const [datosRecibo, setDatosRecibo] = useState([]);
  const IDEst = props.datosPagosEstudiante.IDEst;
  const token = useToken().token;
  const IDCasa = token.casaSel;

  function CierraPagosEstudiante() {
    props.setMostrarPagosEstudiante(false);
  }
  function PagoCompletado() {
    props.setBorrado(true);
  }

  function TerminaRecibo(ev) {
    console.log("Hola");
    if (ev !== undefined) {
      ev.preventDefault();
    }
    setMuestraRecibo(false);
  }

  useEffect(() => {
    const setBorrado = props.setBorrado;
    setBorrado(false);
    function PrimeraFecha(pagos) {
      const pagosOrdenados = pagos.sort((pago1, pago2) =>
        pago1.FechaPag > pago2.FechaPag ? 1 : -1
      );
      return (
        pagosOrdenados[0].FechaPag.substr(0, 4) +
        "-" +
        pagosOrdenados[0].FechaPag.substr(4, 2) +
        "-" +
        pagosOrdenados[0].FechaPag.substr(6, 2)
      );
    }
    function UltimaFecha(pagos) {
      const pagosOrdenados = pagos.sort((pago1, pago2) =>
        pago1.FechaPag < pago2.FechaPag ? 1 : -1
      );
      return (
        pagosOrdenados[0].FechaPag.substr(0, 4) +
        "-" +
        pagosOrdenados[0].FechaPag.substr(4, 2) +
        "-" +
        pagosOrdenados[0].FechaPag.substr(6, 2)
      );
    }
    setIsLoading(true);
    fetch("https://www.copilco.net/inc/funciones.React.php", {
      method: "POST",
      body: JSON.stringify({
        accion: "pagosEstudiante",
        IDEst: IDEst,
        IDCasa: IDCasa,
      }),
      headers: {
        "content-type": "application/json",
      },
    })
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        var pagosEstudianteObj = {};
        var formasPagoObj = {};
        var Nombre = "";
        if (data === null) {
          setPagosEstudianteLoaded([]);
          //console.log("ERROR. NO SE RECIBIO RESPUESTA DEL SERVICIO");
        } else if (data.ERROR !== "SI") {
          //console.log("data", data);
          for (const key in data) {
            const datos = {
              ...data[key],
            };
            if (key === "RIngresos") {
              pagosEstudianteObj = Object.keys(datos).map((key) => datos[key]);
            } else if (key === "RFormasPago") {
              formasPagoObj = Object.keys(datos).map((key) => datos[key]);
            } else if (key === "Nombre") {
              Nombre = data.Nombre;
            }
          }
          //console.log("pagosEstudianteObj", pagosEstudianteObj);
          //console.log("Nombre", Nombre);
          //console.log("formasPagoObj", formasPagoObj);
          setPagosEstudianteLoaded(pagosEstudianteObj);
          setFormasPago(formasPagoObj);
          setNombre(Nombre);
          setFiltroPagos({
            Renta: true,
            DepGar: true,
            Llaves: false,
            FechaIni:
              pagosEstudianteObj.length > 0
                ? PrimeraFecha(pagosEstudianteObj)
                : "",
            FechaFin:
              pagosEstudianteObj.length > 0
                ? UltimaFecha(pagosEstudianteObj)
                : "",
          });
          setIsLoading(false);
        } else {
          setPagosEstudianteLoaded([]);
          console.log("ERROR", data.DESCERROR);
          setIsLoading(false);
        }
      });
  }, [IDEst, IDCasa, props.setBorrado]);

  function editaPago(ev) {
    ev.preventDefault();
    const pago = pagosEstudianteLoaded.filter(
      (pago) => pago.IDIng === ev.target.id
    );
    setDatosEdicionPago(pago[0]);
    setMuestraEdicionPago(true);
  }

  function enviaPago(ev) {
    ev.preventDefault();
    const pago = pagosEstudianteLoaded.find(
      (pago) => pago.IDIng === ev.target.id
    );
    pago.DiaCorte = props.datosPagosEstudiante.DiaCorte;
    pago.Nombre = props.datosPagosEstudiante.Nombre;
    console.log(props, pago, datosEdicionPago);
    setDatosRecibo(pago);
    setMuestraRecibo(true);
  }

  const borrarPago = async (ev) => {
    ev.preventDefault();
    const pago = pagosEstudianteLoaded.filter(
      (pago) => pago.IDIng === ev.target.id
    );
    if (
      window.confirm(
        "borrar el pago del " +
          FechaMMMDeString(pago[0].FechaPag) +
          " por " +
          FormateaCurrency.format(pago[0].Monto) +
          "?"
      )
    ) {
      const envio = await EnviaDatosAServicio(pago[0], "borrarPago");
      if (envio === null) {
        //console.log("sin respuesta");
        props.setDialogoOK({
          texto: "ERROR: No hubo respuesta del servidor",
          mostrandoError: true,
          recargar: false,
          cerrarVentana: "",
        });
        props.setMuestraDialogoOK(true);
        //alert("ERROR: No hubo respuesta del servidor");
      } else if (envio.ERROR === "") {
        toast("Ingreso eliminado", {
          position: "top-center",
          autoClose: 1000,
          hideProgressBar: true,
          transition: Slide,
        });
        props.setBorrado(true);
        /*
        props.setDialogoOK({
          texto: "Borrado OK",
          mostrandoError: false,
          recargar: true,
          cerrarVentana: "",
        });
        props.setMuestraDialogoOK(true);*/
        //props.setBorrado(true);
        //alert("Borrado OK");
      } else {
        //console.log(envio);
        props.setDialogoOK({
          texto: "ERROR: " + envio.DESCERROR,
          mostrandoError: true,
          recargar: false,
          cerrarVentana: "",
        });
        props.setMuestraDialogoOK(true);
        //alert(envio.DESCERROR);
      }
    }
  };

  function invierteOrden() {
    if (iconoOrdena === "fas fa-sort-amount-up-alt")
      setIconoOrdena("fas fa-sort-amount-down-alt");
    else setIconoOrdena("fas fa-sort-amount-up-alt");
  }

  function ordenaPagos(pago1, pago2) {
    var comp1 = pago1.FechaPag;
    var comp2 = pago2.FechaPag;
    if (pago1.FechaPag === pago2.FechaPag) {
      comp1 = pago1.MesQPag;
      comp2 = pago2.MesQPag;
    }
    if (iconoOrdena === "fas fa-sort-amount-up-alt") {
      return comp1 < comp2 ? 1 : -1;
    } else {
      return comp1 > comp2 ? 1 : -1;
    }
  }

  function quePagosMostrar(pago) {
    if (
      pago.FechaPag >= filtroPagos.FechaIni.replace(/-/gi, "") &&
      pago.FechaPag <= filtroPagos.FechaFin.replace(/-/gi, "")
    ) {
      if (pago.Concepto === "Renta" && filtroPagos.Renta) return true;
      if (pago.Concepto === "Deposito en garantia" && filtroPagos.DepGar)
        return true;
      if (pago.Concepto === "Duplicado llaves" && filtroPagos.Llaves)
        return true;
    }
    return false;
  }

  function FiltraPagos(ev) {
    setPosFiltroPagos({
      x: ev.target.offsetLeft,
      y: ev.target.offsetTop + ev.target.offsetHeight,
    });
    setMuestraFiltroPagos(true);
  }

  function FormaDePagoDeIDForPago(IDForPag) {
    const fp = formasPago.filter(
      (formapago) => formapago.IDForPag === IDForPag
    );
    //console.log("fp", fp);
    if (fp.length > 0) return fp[0].FormaPago;
    return "desconocido";
  }
  //  console.log("props", props);
  //console.log("pagos recibidos", pagosEstudianteLoaded);
  /*****************************************************************************/
  /********************************* RENDER ************************************/
  /*****************************************************************************/
  if (isLoading) {
    return (
      <section className={classes.modal}>
        <h1>Loading...</h1>
      </section>
    );
  }

  return (
    <div className={classes.modal}>
      <div className={classes.contBotTop}>
        <button onClick={CierraPagosEstudiante}>
          <i className="fas fa-times"></i>
        </button>
      </div>
      <h1 className={classes.h1loc}>{nombre}</h1>
      {pagosEstudianteLoaded.length > 0 && (
        <div className={classes.divBotones}>
          <button onClick={invierteOrden} className={classes.botones}>
            <i className={iconoOrdena}></i>
          </button>
          <button onClick={FiltraPagos} className={classes.botones}>
            <i className="fas fa-filter"></i>
          </button>
        </div>
      )}
      {muestraFiltroPagos && (
        <FiltroPagosEstudiante
          filtroPagos={filtroPagos}
          setFiltroPagos={setFiltroPagos}
          posFiltroPagos={posFiltroPagos}
        />
      )}
      {muestraFiltroPagos && <Backdrop recarga={setMuestraFiltroPagos} />}
      {muestraEdicionPago && (
        <NuevoIngreso
          PagoCompletado={PagoCompletado}
          setMostrarNuevo={setMuestraEdicionPago}
          edicionPago={datosEdicionPago}
          setDialogoOK={props.setDialogoOK}
          setMuestraDialogoOK={props.setMuestraDialogoOK}
        />
      )}
      {muestraRecibo && (
        <ReciboPago
          datosRecibo={datosRecibo}
          TerminaRecibo={TerminaRecibo}
          setDialogoOK={props.setDialogoOK}
          setMuestraDialogoOK={props.setMuestraDialogoOK}
        />
      )}
      {muestraRecibo && <Backdrop />}
      {muestraFiltroPagos && <Backdrop recarga={setMuestraFiltroPagos} />}
      {pagosEstudianteLoaded.length === 0 && <h1>NO HAY PAGOS REGISTRADOS</h1>}
      {pagosEstudianteLoaded.filter((pago) => quePagosMostrar(pago)).length ===
        0 && <h1>NO HAY PAGOS REGISTRADOS CON ESOS FILTROS</h1>}
      {pagosEstudianteLoaded
        .filter((pago) => quePagosMostrar(pago))
        .sort((pago1, pago2) => ordenaPagos(pago1, pago2))
        .map((pago) => (
          <section key={pago.IDIng} className={classes.marcoPago}>
            <div className={classes.contPago}>
              <label className={classes.tituloPago}>
                # Recibo:
                <span className={classes.datoPago}>{pago.IDIng}</span>
              </label>
              <label className={classes.tituloPago}>
                Fecha Pago:
                <span className={classes.datoPago}>
                  {FechaMMMDeString(pago.FechaPag)}
                </span>
              </label>
              <label className={classes.tituloPago}>
                Monto:
                <span className={classes.datoPago}>
                  {FormateaCurrency.format(pago.Monto)}
                </span>
              </label>
              <label className={classes.tituloPago}>
                Concepto:
                <span className={classes.datoPago}>{pago.Concepto}</span>
              </label>
              {pago.MesQPag === "0" || pago.MesQPag === "-" ? null : (
                <label className={classes.tituloPago}>
                  Mes Que Paga:
                  <span className={classes.datoPago}>
                    {FechaMMMDeString(pago.MesQPag)}
                  </span>
                </label>
              )}
              <label className={classes.tituloPago}>
                Forma de pago:
                <span className={classes.datoPago}>
                  {FormaDePagoDeIDForPago(pago.IDForPag)}
                </span>
              </label>
              <label className={classes.tituloPago}>
                Habitación:
                <span className={classes.datoPago}>{pago.Habitacion}</span>
              </label>
              {pago.Observaciones === "" ? null : (
                <label className={classes.tituloPago}>
                  Observaciones:
                  <span className={classes.datoPago}>{pago.Observaciones}</span>
                </label>
              )}
            </div>
            <div className={classes.divBotonesPago}>
              {props.nivel === 1 ? (
                <button
                  className={classes.botonesPago}
                  onClick={editaPago}
                  id={pago.IDIng}
                >
                  <i id={pago.IDIng} className="fas fa-edit"></i>
                </button>
              ) : null}
              {props.nivel === 1 ? (
                <button
                  className={classes.botonesPago}
                  onClick={borrarPago}
                  id={pago.IDIng}
                >
                  <i id={pago.IDIng} className="fas fa-times"></i>
                </button>
              ) : null}
              {props.nivel === 1 ? (
                <button
                  className={classes.botonesPago}
                  onClick={enviaPago}
                  id={pago.IDIng}
                >
                  <i id={pago.IDIng} className="fas fa-envelope"></i>
                </button>
              ) : null}
            </div>
          </section>
        ))}
    </div>
  );
}

export default PagosEstudiante;
