import { useEffect, useState } from "react";
import classes from "./RecurrentesProgramados.module.css";
import { EnviaDatosANuevoServicio } from "../../Funciones/Funciones";
import useToken from "../login/useToken";
import ListaRecurrentes from "./RecurrentesLista";
import Backdrop from "../layout/backdrop";
import NuevoRecurrente from "./RecurrenteNuevo";

function PagosProgramados(props) {
  const [isLoading, setIsLoading] = useState(false);
  const [recurrentesLoaded, setRecurrentesLoaded] = useState([]);
  const [muestraNuevoRecurrente, setMuestraNuevoRecurrente] = useState(false);
  const [recargar, setRecargar] = useState(false);
  const token = useToken().token;
  const idCasa = token.casaSel;
  const idUsu = token.Id;

  function OrdenaRecurrentes() {}

  function FiltraRecurrentes() {}

  function AgregaRecurrente() {
    setMuestraNuevoRecurrente(true);
  }

  function SeleccionaPagoHandler(recurrente) {
    console.log(recurrente);
  }

  function Cierra() {
    props.setMuestraPagosProgramados(false);
  }

  function respServ(resp) {
    //console.log(resp);
    if (resp.Error === "") {
      setRecurrentesLoaded(resp.Programados);
    } else {
      console.log(resp.DESCERROR);
    }
  }

  useEffect(() => {
    setRecargar(false);
    setIsLoading(true);
    const datos = {
      IDCasa: idCasa,
    };
    //console.log(datos);
    EnviaDatosANuevoServicio(
      "PagosProgramados",
      datos,
      respServ,
      setIsLoading,
      null,
      idUsu
    );
  }, [idUsu, idCasa, recargar]);

  /***************************************************************************/
  /************************* RENDER ******************************************/
  /***************************************************************************/
  if (isLoading) {
    //console.log("cargando");
    return (
      <section>
        <h1>Loading...</h1>
      </section>
    );
  }
  //console.log(recurrentesLoaded);
  return (
    <section className={classes.modal}>
      <button className={classes.botCancela} onClick={Cierra}>
        <i className="fas fa-times"></i>
      </button>
      <div className={classes.TituloBoton}>
        <h1>Pagos Programados</h1>
        <div className="contBotones">
          {1 > 0 ? (
            <button className="botAgregaEst" onClick={OrdenaRecurrentes}>
              <i className="fas fa-sort-amount-up-alt"></i>
            </button>
          ) : null}
          <button className="botAgregaEst" onClick={FiltraRecurrentes}>
            <i className="fas fa-filter"></i>
          </button>
          {props.nivel === 1 ? (
            <button className="botAgregaEst" onClick={AgregaRecurrente}>
              <i className="fas fa-plus-square"></i>
            </button>
          ) : null}
        </div>
      </div>

      <ListaRecurrentes
        nivel={props.nivel}
        setRecargar={props.setRecargar}
        setDialogoOK={props.setDialogoOK}
        setMuestraDialogoOK={props.setMuestraDialogoOK}
        muestra={props.muestra}
        SeleccionaPagoHandler={SeleccionaPagoHandler}
        recurrentesLoaded={recurrentesLoaded}
        tipo={props.tipo}
      />
      {muestraNuevoRecurrente && (
        <NuevoRecurrente
          nivel={props.nivel}
          setRecargar={setRecargar}
          setDialogoOK={props.setDialogoOK}
          setMuestraDialogoOK={props.setMuestraDialogoOK}
          setMuestraNuevoRecurrente={setMuestraNuevoRecurrente}
        />
      )}
      {muestraNuevoRecurrente && <Backdrop />}
    </section>
  );
}

export default PagosProgramados;
