import { useRef, useEffect, useState } from "react";
import {
  EnviaDatosANuevoServicio,
  FechaParaInputDate,
} from "../../Funciones/Funciones"; // "../   ../funciones/Funciones";
import classes from "./EstudianteEdicion.module.css";
import useToken from "../login/useToken";
import CargaFoto from "../layout/CargaFoto";
//import RenuevaContrato from "./EstudianteRenuevaContrato";
import Backdrop from "../layout/backdrop";
import Select from "react-select";
import { NumericFormat } from "react-number-format";
import CambiaHabitacion from "../habitaciones/HabitacionCambia";
import OKModal from "../dialogos/dialogoOK";
import FechasNuevoContrato from "./EstudianteFechasContrato";
import { Slide, toast } from "react-toastify";

function EditaEstudiante(props) {
  const nombreInputRef = useRef();
  const observacionesRef = useRef();
  const direccionInputRef = useRef();
  const numIntInputRef = useRef();
  const coloniaInputRef = useRef();
  const municipioInputRef = useRef();
  const estadoInputRef = useRef();
  const [cpInput, setCPInput] = useState(props.estudiante.CP);
  const paisInputRef = useRef();
  const [telFijoInput, setTelFijoInput] = useState(props.estudiante.TelFijo);
  const [telMovilInput, setTelMovilInput] = useState(props.estudiante.TelMovil);
  const eMailInputRef = useRef();
  const fechaNacInputRef = useRef();
  const facultadInputRef = useRef();
  const carreraInputRef = useRef();
  const [semestreInput, setSemestreInput] = useState(props.estudiante.Semestre);
  const otrosEstInputRef = useRef();
  const identificacionInputRef = useRef();
  const credEstInputRef = useRef();
  const compDomInputRef = useRef();
  const reglamentoInputRef = useRef();
  const fechaIngInputRef = useRef();
  const iniciaConInputRef = useRef();
  const venceConInputRef = useRef();
  const [diaCorteInput, setDiaCorteInput] = useState(props.estudiante.DiaCorte);
  const parentesco1InputRef = useRef();
  const nombre1InputRef = useRef();
  const direccion1InputRef = useRef();
  const eMail1InputRef = useRef();
  const [telMovil1Input, setTelMovil1Input] = useState(
    props.estudiante.TelMobiC1
  );
  const parentesco2InputRef = useRef();
  const nombre2InputRef = useRef();
  const direccion2InputRef = useRef();
  const eMail2InputRef = useRef();
  const [telMovil2Input, setTelMovil2Input] = useState(
    props.estudiante.TelMobiC2
  );
  const habitacionInputRef = useRef();
  const [recarga, setRecarga] = useState(false);
  const [isLoading, setIsLoading] = useState([]);
  const [loadedLlaves, setLoadedLlaves] = useState([]);
  const [muestraModal, setMuestraModal] = useState(false);
  const [timeStamp, setTimeStamp] = useState(new Date());
  const [tit_Tipo, setTit_Tipo] = useState([]);
  const [muestraRenueva, setMuestraRenueva] = useState(false);
  const [llaveSeleccionada, setLlaveSeleccionada] = useState(
    props.estudiante.Llaves
  );
  const [porcPagoTardioInput, setPorcPagoTardioInput] = useState(
    props.estudiante.PorcPagoT === ""
      ? props.estudiante.DefPorcPagoExt
      : props.estudiante.PorcPagoT
  );
  const [diasPagoTardioInput, setDiasPagoTardioInput] = useState(
    props.estudiante.DiasPagoT === ""
      ? props.estudiante.DefDiasPagoExt
      : props.estudiante.DiasPagoT
  );
  const [porcPagoHabitacionInput, setPorcPagoHabitacionInput] = useState(
    props.estudiante.PorcPagoH === "" ? "100" : props.estudiante.PorcPagoH
  );
  const [descPagEfeInput, setDescPagEfeInput] = useState(
    props.estudiante.DescPagoE === ""
      ? props.estudiante.DefDescPagEfe
      : props.estudiante.DescPagoE
  );
  const nombrefotoInputRef = useRef();
  const [muestraCambiaHabitacion, setMuestraCambiaHabitacion] = useState(false);
  const { token } = useToken();
  const idCasa = token.casaSel;
  const idUsu = token.Id;
  const [muestraDialogoOK, setMuestraDialogoOK] = useState(false);
  const [dialogoOK, setDialogoOK] = useState({
    texto: "",
    mostrandoError: false,
    recargar: false,
    cerrarVentana: "",
  });
  const [tipoRenovacion, setTipoRenovacion] = useState(0);
  const fechasNuevasRef = useRef(null);
  const recargaXFotoRef = useRef("NO");
  const [datosCambiaHabitacion, setDatosCambiaHabitacion] = useState({});
  const [hayCambios, setHayCambios] = useState(false);

  const muestraContrato = () => {
    window.open(
      "https://www.copilco.net/expedientes/documentos/" +
        props.estudiante.IDEst +
        "_Contrato.pdf?" +
        timeStamp
    );
  };

  function handleSelect(value) {
    setLlaveSeleccionada(value);
    setHayCambios(true);
  }

  function contesta(ev, resp) {
    ev.preventDefault();
    setMuestraDialogoOK(false);
    setTipoRenovacion(resp);
    setMuestraRenueva(true);
  }

  function cambiaHabitacion(ev) {
    ev.preventDefault();
    setDatosCambiaHabitacion({ IDEst: props.estudiante.IDEst });
    setMuestraCambiaHabitacion(true);
  }

  function respServ(tipo) {
    console.log("tipo:", tipo);
    if (tipo !== "") {
      if (tipo?.Error === "SI") {
        props.setDialogoOK({
          mostrandoError: true,
          texto: tipo.DESCERROR,
          recargar: false,
        });
        props.setMuestraDialogoOK(true);
        tipo.Error = "";
      } else {
        if (tipo.origen === "Boton") {
          toast("Guardado OK", {
            position: "top-center",
            autoClose: 1000,
            hideProgressBar: true,
            transition: Slide,
          });
          props.setMostrarEdicion(false);
          props.setRecargar(true);
        } else if (tipo.origen === "contrato") {
          //console.log(estudianteEnviado)
          const datos = {
            IDEst: tipo.IDEst,
          };
          EnviaDatosANuevoServicio(
            "GeneraNuevoContrato",
            datos,
            respServ,
            setIsLoading,
            null,
            idUsu
          );
        } else if (tipo.origen === "generaContrato") {
          if (tipo.Error === "") {
            toast("Nuevo contrato generado", {
              position: "top-center",
              autoClose: 1000,
              hideProgressBar: true,
              transition: Slide,
            });
            muestraContrato();
          } else {
            props.setDialogoOK({
              texto: "Error al generar: " + tipo.DESCERROR,
              mostrandoError: true,
              recargar: false,
              cerrarVentana: "",
            });
            props.setMuestraDialogoOK(true);
          }
        } else if (tipo.origen === "generaPDF") {
          generaPDF();
        } else if (tipo.origen === "GeneraContratoParaFirma") {
          if (tipo.Error === "") {
            toast("Nuevo contrato generado", {
              position: "top-center",
              autoClose: 1000,
              hideProgressBar: true,
              transition: Slide,
            });
            muestraContrato();
            props.setMostrarEdicion(false);
          } else {
            props.setDialogoOK({
              texto: "Error al generar: " + tipo.DESCERROR,
              mostrandoError: true,
              recargar: false,
              cerrarVentana: "",
            });
            props.setMuestraDialogoOK(true);
          }
        } else if (tipo.origen === "contratoFirmadoGD") {
          //console.log(fechasNuevasRef.current.PrecioNuevo);
          const datos = {
            IDEst: tipo.IDEst,
            PrecioNuevo: fechasNuevasRef.current.PrecioNuevo,
            IDHab: props.estudiante.IDHab,
          };
          if (tipo.ERROR === "") {
            EnviaDatosANuevoServicio(
              "ContratoFirmado",
              datos,
              respServ,
              setIsLoading,
              null,
              idUsu
            );
          } else {
            props.setDialogoOK({
              texto: "Error al guardar: " + tipo.DESCERROR,
              mostrandoError: true,
              recargar: false,
              cerrarVentana: "",
            });
            props.setMuestraDialogoOK(true);
          }
        } else if (tipo.origen === "contratoFirmado") {
          if (tipo.Error === "") {
            toast("Nuevo contrato generado", {
              position: "top-center",
              autoClose: 1000,
              hideProgressBar: true,
              transition: Slide,
            });
            muestraContrato();
            props.setMostrarEdicion(false);
          } else {
            props.setDialogoOK({
              texto: "Error al generar contrato: " + tipo.DESCERROR,
              mostrandoError: true,
              recargar: false,
              cerrarVentana: "",
            });
            props.setMuestraDialogoOK(true);
          }
        } else {
          console.log("respserv", tipo);
        }
      }
    }
  }

  function generaPDF() {
    const datos = {
      IDEst: props.estudiante.IDEst,
      fechas: fechasNuevasRef.current,
    };
    //console.log(datos);
    EnviaDatosANuevoServicio(
      "GeneraContratoParaFirma",
      datos,
      respServ,
      setIsLoading,
      null,
      idUsu
    );
  }

  function cambiaFotoEstudiante(ev) {
    ev.preventDefault();
    const temp = {
      titulo: "Carga foto estudiante",
      tipo: "foto",
    };
    setTit_Tipo(temp);
    setMuestraModal(true);
  }

  function cambiaIDEstudiante(ev) {
    ev.preventDefault();
    const temp = {
      titulo: "Carga Identificación",
      tipo: "ID",
    };
    setTit_Tipo(temp);
    setMuestraModal(true);
  }

  function cambiaCEEstudiante(ev) {
    ev.preventDefault();
    const temp = {
      titulo: "Carga Credencial",
      tipo: "CredencialEstudiante",
    };
    setTit_Tipo(temp);
    setMuestraModal(true);
  }

  function cambiaCDEstudiante(ev) {
    ev.preventDefault();
    const temp = {
      titulo: "Carga Comp Domicilio",
      tipo: "ComprobanteDomicilio",
    };
    setTit_Tipo(temp);
    setMuestraModal(true);
  }

  function cambiaDGEstudiante(ev) {
    ev.preventDefault();
    const temp = {
      titulo: "Carga Dep Garantía",
      tipo: "DepositoGarantia",
    };
    setTit_Tipo(temp);
    setMuestraModal(true);
  }

  function renuevaContratoEstudiante(ev) {
    ev.preventDefault();
    setDialogoOK({
      mostrandoError: true,
      texto: "¿Quieres generar el PDF para firma o ya firmó el nuevo contrato?",
      botones: {
        textoBoton2: "Ya Firmó",
        textoBoton1: "Generar PDF",
      },
      recargar: false,
    });
    setMuestraDialogoOK(true);
  }

  function cambiaContratoEstudiante(ev) {
    ev.preventDefault();
    const temp = {
      titulo: "Carga Contrato",
      tipo: "Contrato",
    };
    setTit_Tipo(temp);
    setMuestraModal(true);
  }

  const regeneraContratoEstudiante = async (ev) => {
    ev.preventDefault();
    console.log("RCE");
    if (hayCambios) {
      if (
        window.confirm(
          "Generar nuevo contrato con los datos actuales? Ojo. Se guardará cualquier cambio que hayas hecho ahorita"
        )
      ) {
        guardaDatos("contrato");
      }
    } else {
      const datos = {
        IDEst: props.estudiante.IDEst,
      };
      console.log("Datos", datos);
      EnviaDatosANuevoServicio(
        "GeneraNuevoContrato",
        datos,
        respServ,
        setIsLoading,
        null,
        idUsu
      );
    }
  };

  function CancelaNuevo() {
    props.setMostrarEdicion(false);
    //props.setBorrado(true);
    if (recargaXFotoRef.current !== "NO") {
      props.setRecargaFoto(true);
    }
  }

  useEffect(() => {
    function setLoadedLlavesFunction(llaves) {
      var llavesArr = [];
      Object.keys(llaves).map((key) => {
        if (llaves[key].IDLlave !== undefined) {
          llavesArr.push({
            IDLlave: llaves[key].IDLlave,
            Llave: llaves[key].Llave,
          });
        }
        return 1;
      });
      props.estudiante.Llaves.map((llave) => {
        llavesArr.push({ IDLlave: llave.IDLlave, Llave: llave.Llave });
        return 1;
      });
      setLlaveSeleccionada(
        props.estudiante.Llaves.map((llave) => {
          return { value: llave.IDLlave, label: llave.Llave };
        })
      );
      setLoadedLlaves(llavesArr);
    }

    setIsLoading(true);
    setRecarga(false);
    const IDCasaObj = {
      IDCasa: idCasa,
    };
    EnviaDatosANuevoServicio(
      "llavesDisponibles",
      IDCasaObj,
      setLoadedLlavesFunction,
      setIsLoading,
      null,
      idUsu
    );
  }, [idUsu, idCasa, recarga, token, props.estudiante.Llaves]);

  const guardaDatos = async (origen) => {
    const datosOK = validaDatos();
    if (datosOK === "") {
      //console.log(eMail1InputRef.current.value);
      const estudiante = {
        Nombre: nombreInputRef.current.value.toString().toUpperCase(),
        CalleNum: direccionInputRef.current.value.toString().toUpperCase(),
        NumInt: numIntInputRef.current.value.toString().toUpperCase(),
        Colonia: coloniaInputRef.current.value.toString().toUpperCase(),
        Municipio: municipioInputRef.current.value.toString().toUpperCase(),
        Estado: estadoInputRef.current.value.toString().toUpperCase(),
        CP: cpInput,
        Pais: paisInputRef.current.value.toString().toUpperCase(),
        TelFijo: telFijoInput,
        TelMovil: telMovilInput,
        Email: eMailInputRef.current.value.toString().toLowerCase(),
        FechaNac: fechaNacInputRef.current.value
          .toString()
          .toUpperCase()
          .replace(/-/gi, ""),
        Facultad: facultadInputRef.current.value.toString().toUpperCase(),
        Carrera: carreraInputRef.current.value.toString().toUpperCase(),
        Semestre: semestreInput,
        OtroMotivo: otrosEstInputRef.current.value.toString().toUpperCase(),
        Ident: identificacionInputRef.current.value.toString().toUpperCase(),
        CredEst: credEstInputRef.current.value.toString().toUpperCase(),
        CompDom: compDomInputRef.current.value.toString().toUpperCase(),
        Reglam: reglamentoInputRef.current.value.toString().toUpperCase(),
        FechaIng: fechaIngInputRef.current.value
          .toString()
          .toUpperCase()
          .replace(/-/gi, ""),
        IniciaCont: iniciaConInputRef.current.value
          .toString()
          .toUpperCase()
          .replace(/-/gi, ""),
        VenceCont: venceConInputRef.current.value
          .toString()
          .toUpperCase()
          .replace(/-/gi, ""),
        DiaCorte: diaCorteInput,
        Contac1: parentesco1InputRef.current.value.toString().toUpperCase(),
        NombreC1: nombre1InputRef.current.value.toString().toUpperCase(),
        DireccC1: direccion1InputRef.current.value.toString().toUpperCase(),
        MailC1: eMail1InputRef.current.value,
        TelMobiC1: telMovil1Input,
        Contac2: parentesco2InputRef.current.value.toString().toUpperCase(),
        NombreC2: nombre2InputRef.current.value.toString().toUpperCase(),
        DireccC2: direccion2InputRef.current.value.toString().toUpperCase(),
        MailC2: eMail2InputRef.current.value.toString().toLowerCase(),
        TelMobiC2: telMovil2Input,
        IDHab: props.estudiante.IDHab,
        IDLlave: llaveSeleccionada,
        NombreFoto: nombrefotoInputRef.current.value.toString(),
        PorcPagoExtemp: porcPagoTardioInput,
        DiasPagoExtemp: diasPagoTardioInput,
        PorcPago: porcPagoHabitacionInput,
        DescPagEfe: descPagEfeInput,
        Observaciones: observacionesRef.current.value.toString().toUpperCase(),
        IDEst: props.estudiante.IDEst,
        key: 100000,
        origen: origen,
        IDCasa: idCasa,
      };
      console.log("estudiante", estudiante);
      EnviaDatosANuevoServicio(
        "EditaInquilino",
        estudiante,
        respServ,
        setIsLoading,
        null,
        idUsu
      );
    } else {
      props.setDialogoOK({
        texto: "Revisa los siguientes datos\n" + datosOK,
        mostrandoError: true,
        recargar: false,
        cerrarVentana: "",
      });
      props.setMuestraDialogoOK(true);
    }
  };
  const submitHandler = async (ev) => {
    ev.preventDefault();
    await guardaDatos("Boton");
  };

  function validaDatos() {
    var respuesta = "";

    if (nombreInputRef.current.value === "") {
      respuesta = respuesta + "Nombre\n";
    }
    if (direccionInputRef.current.value === "") {
      respuesta = respuesta + "Dirección\n";
    }
    if (coloniaInputRef.current.value === "") {
      respuesta = respuesta + "Colonia\n";
    }
    if (municipioInputRef.current.value === "") {
      respuesta = respuesta + "Municipio\n";
    }
    if (estadoInputRef.current.value === "") {
      respuesta = respuesta + "Estado\n";
    }
    if (cpInput === "") {
      respuesta = respuesta + "Código postal\n";
    }
    if (paisInputRef.current.value === "") {
      respuesta = respuesta + "País\n";
    }
    if (telFijoInput === "") {
      respuesta = respuesta + "Teléfono Fijo\n";
    }
    if (telMovilInput === "") {
      respuesta = respuesta + "Teléfono móvil\n";
    }
    if (eMailInputRef.current.value === "") {
      respuesta = respuesta + "e-mail\n";
    }
    if (fechaNacInputRef.current.value === "") {
      respuesta = respuesta + "Fecha de nacimiento\n";
    }
    if (
      facultadInputRef.current.value === "" &&
      otrosEstInputRef.current.value === ""
    ) {
      respuesta = respuesta + "Facultad u Otros estudios\n";
    }
    if (
      carreraInputRef.current.value === "" &&
      facultadInputRef.current.value !== ""
    ) {
      respuesta = respuesta + "Carrera\n";
    }
    if (semestreInput === "" && facultadInputRef.current.value !== "") {
      respuesta = respuesta + "Semestre\n";
    }
    if (identificacionInputRef.current.value === "0") {
      respuesta = respuesta + "Identificación\n";
    }
    if (credEstInputRef.current.value === "0") {
      respuesta = respuesta + "Credencial de estudios\n";
    }
    if (compDomInputRef.current.value === "0") {
      respuesta = respuesta + "Comprobante de domicilio\n";
    }
    if (reglamentoInputRef.current.value === "0") {
      respuesta = respuesta + "Reglamento\n";
    }
    if (fechaIngInputRef.current.value === "") {
      respuesta = respuesta + "Fecha de ingreso\n";
    }
    if (iniciaConInputRef.current.value === "") {
      respuesta = respuesta + "Fecha que inicia contrato\n";
    }
    if (venceConInputRef.current.value === "") {
      respuesta = respuesta + "Fecha que vence contrato\n";
    }
    if (diaCorteInput === "" || diaCorteInput === undefined) {
      respuesta = respuesta + "Dia de corte\n";
    } else if (parseInt(diaCorteInput) < 1 || parseInt(diaCorteInput) > 31) {
      respuesta = respuesta + "El día de corte debe estar entre 1 y 31\n";
    }
    if (parentesco1InputRef.current.value === "") {
      respuesta = respuesta + "Parentesco del contacto 1\n";
    }
    if (nombre1InputRef.current.value === "") {
      respuesta = respuesta + "Nombre del contacto 1\n";
    }
    if (direccion1InputRef.current.value === "") {
      respuesta = respuesta + "Dirección del contacto 1\n";
    }
    if (telMovil1Input === "") {
      respuesta = respuesta + "Teléfono movil del contacto 1\n";
    }
    if (
      parentesco2InputRef.current.value === "" &&
      (nombre2InputRef.current.value !== "" ||
        (telMovil2Input !== "" && telMovil2Input !== undefined))
    ) {
      console.log(nombre2InputRef.current.value, telMovil2Input);
      respuesta = respuesta + "Parentesco del contacto 2\n";
    }
    if (
      nombre2InputRef.current.value === "" &&
      (parentesco2InputRef.current.value !== "" ||
        (telMovil2Input !== "" && telMovil2Input !== undefined))
    ) {
      respuesta = respuesta + "Nombre del contacto 2\n";
    }
    if (
      telMovil2Input === "" &&
      (parentesco2InputRef.current.value !== "" ||
        nombre2InputRef.current.value !== "")
    ) {
      respuesta = respuesta + "Teléfono movil del contacto 2\n";
    }
    if (llaveSeleccionada === null) {
      //respuesta = respuesta + "Llave\n";
    }
    if (porcPagoTardioInput === 0 && diasPagoTardioInput !== 0) {
      respuesta = respuesta + "% por pago tardío\n";
    }
    if (porcPagoTardioInput > 20) {
      respuesta = respuesta + "% por pago tardío no puede ser mayor a 20%\n";
    }
    if (diasPagoTardioInput === 0 && porcPagoTardioInput !== 0) {
      respuesta = respuesta + "Días para pago tardío\n";
    }
    if (diasPagoTardioInput > 30) {
      respuesta = respuesta + "Días para pago tardío no puede ser mayor a 30\n";
    }
    if (porcPagoHabitacionInput > 100) {
      respuesta = respuesta + "% pago habitación no puede ser mayor a 100\n";
    }
    if (descPagEfeInput < 0) {
      respuesta =
        respuesta + "Desc pago en Efectivo no puede ser menor a cero\n";
    }
    if (descPagEfeInput > props.estudiante.Precio * 0.1) {
      respuesta =
        respuesta +
        "Desc pago en Efectivo no puede ser mayor a " +
        props.estudiante.Precio * 0.1 +
        "\n";
    }
    if (nombrefotoInputRef.current.value === "") {
      nombrefotoInputRef.current.value = nombreInputRef.current.value;
    }
    //respuesta = respuesta + "error forzado para pruebas\n";
    return respuesta;
  }

  function terminaCargaFoto() {
    setTimeStamp(new Date());
    setMuestraModal(false);
    if (tit_Tipo.tipo === "foto") {
      recargaXFotoRef.current = "SI";
    }
  }

  const terminaRenueva = async (fechasNuevas) => {
    fechasNuevasRef.current = fechasNuevas;

    //si es generar el PDF para firma
    if (tipoRenovacion === 1) {
      if (hayCambios) {
        if (
          window.confirm(
            "Ojo. Se guardará cualquier cambio que hayas hecho ahorita pero no se guardaran las fechas ni el precio nuevo"
          )
        ) {
          guardaDatos("generaPDF");
        }
      } else {
        generaPDF();
      }
    } else {
      //si es que ya firmo el contrato, se genera contrato nuevo cambiando las fechas del contrato
      iniciaConInputRef.current.value = fechasNuevas.IniciaCont;
      venceConInputRef.current.value = fechasNuevas.VenceCont;
      guardaDatos("contratoFirmadoGD");
    }

    setMuestraRenueva(false);
  };

  /********************************************************************************/
  /*************************** RENDER *********************************************/
  /********************************************************************************/

  if (isLoading) {
    return (
      <section>
        <h1>Loading...</h1>
      </section>
    );
  }
  //console.log(props);
  return (
    <section className={classes.modal}>
      <div className={classes.contBotTop}>
        {hayCambios && props.nivel === 1 && (
          <button className={classes.botTop} onClick={submitHandler}>
            <i className="fas fa-save"></i>
          </button>
        )}
        <button onClick={CancelaNuevo}>
          <i className="fas fa-times"></i>
        </button>
      </div>

      {muestraModal && (
        <CargaFoto
          IDEst={props.estudiante.IDEst}
          tit_Tipo={tit_Tipo}
          accion="cargaFotoDocumentos"
          setMuestraModal={setMuestraModal}
          terminaCargaFoto={terminaCargaFoto}
        />
      )}
      {muestraModal && <Backdrop zindex={10} />}
      {muestraRenueva && (
        <FechasNuevoContrato
          setMuestraRenueva={setMuestraRenueva}
          terminaRenueva={terminaRenueva}
          estudiante={props.estudiante}
        />
      )}
      {muestraCambiaHabitacion && (
        <CambiaHabitacion
          setBorrado={props.setBorrado}
          estudiante={props.estudiante}
          setMostrarCambiaHabitacion={setMuestraCambiaHabitacion}
          setDialogoOK={props.setDialogoOK}
          setMuestraDialogoOK={props.setMuestraDialogoOK}
          datosCambiaHabitacion={datosCambiaHabitacion}
          setMostrarEdicion={props.setMostrarEdicion}
        />
      )}
      {muestraCambiaHabitacion && <Backdrop />}
      {muestraRenueva && <Backdrop zindex={10} />}
      <h1>VER/EDITAR Estudiante</h1>
      <form>
        <div>
          <img
            key={timeStamp}
            className={classes.fotoEstud}
            src={
              "https://www.copilco.net/expedientes/documentos/" +
              props.estudiante.IDEst +
              "_foto.jpg?" +
              timeStamp
            }
            onError={(e) => {
              e.target.onerror = null;
              e.target.src =
                "https://www.copilco.net/expedientes/documentos/default.jpg";
            }}
            alt={props.estudiante.Nombre}
          />
          <button
            onClick={cambiaFotoEstudiante}
            id={props.estudiante.IDEst}
            className={classes.innbtn}
          >
            <i className="fas fa-edit"></i>
          </button>
        </div>
        <div>
          <label className={classes.tituloP + " " + classes.labelP}>
            Datos Generales
          </label>
        </div>
        <div>
          <label className={classes.labelP}># Inquilino</label>
          <input
            readOnly
            id="NumInq"
            type="text"
            placeholder="por determinar"
            defaultValue={props.estudiante.IDEst}
            className={classes.textareaNE}
          ></input>
        </div>
        <div>
          <label className={classes.labelP}>Nombre completo</label>
          <input
            id="nombre"
            ref={nombreInputRef}
            type="text"
            placeholder="Nombre, Ap Pat, Ap Mat"
            defaultValue={props.estudiante.Nombre}
            className={classes.textareaNE}
            onChange={setHayCambios}
          ></input>
        </div>
        <div>
          <label className={classes.labelP}>Calle y # Ext.</label>
          <input
            id="direccion"
            ref={direccionInputRef}
            type="text"
            className={classes.textareaNE}
            defaultValue={props.estudiante.CalleNum}
            onChange={setHayCambios}
          ></input>
        </div>
        <div>
          <label className={classes.labelP}># Int</label>
          <input
            id="numInt"
            ref={numIntInputRef}
            type="text"
            className={classes.textareaNE}
            defaultValue={props.estudiante.NumInt}
            onChange={setHayCambios}
          ></input>
        </div>
        <div>
          <label className={classes.labelP}>Colonia</label>
          <input
            id="colonia"
            ref={coloniaInputRef}
            type="text"
            className={classes.textareaNE}
            defaultValue={props.estudiante.Colonia}
            onChange={setHayCambios}
          ></input>
        </div>
        <div>
          <label className={classes.labelP}>Alcaldia/Municipio</label>
          <input
            id="municipio"
            ref={municipioInputRef}
            type="text"
            className={classes.textareaNE}
            defaultValue={props.estudiante.Municipio}
            onChange={setHayCambios}
          ></input>
        </div>
        <div>
          <label className={classes.labelP}>Estado</label>
          <input
            id="estado"
            ref={estadoInputRef}
            type="text"
            className={classes.textareaNE}
            defaultValue={props.estudiante.Estado}
            onChange={setHayCambios}
          ></input>
        </div>
        <div>
          <label className={classes.labelP}>Código Postal</label>
          <NumericFormat
            maxLength="5"
            id="cp"
            type="text"
            className={classes.textareaNE}
            placeholder="00000"
            defaultValue={props.estudiante.CP}
            onValueChange={(values) => {
              setCPInput(values.floatValue);
            }}
            onChange={setHayCambios}
          ></NumericFormat>
        </div>
        <div>
          <label className={classes.labelP}>País</label>
          <input
            id="pais"
            ref={paisInputRef}
            type="text"
            className={classes.textareaNE}
            defaultValue={props.estudiante.Pais}
            onChange={setHayCambios}
          ></input>
        </div>
        <div>
          <label className={classes.labelP}>teléfono fijo</label>
          <NumericFormat
            maxLength="20"
            id="telFijo"
            type="tel"
            className={classes.textareaNE}
            placeholder="5555555555"
            defaultValue={props.estudiante.TelFijo}
            onValueChange={(values) => {
              setTelFijoInput(values.floatValue);
            }}
            onChange={setHayCambios}
          ></NumericFormat>
        </div>
        <div>
          <label className={classes.labelP}>teléfono movil</label>
          <NumericFormat
            maxLength="20"
            id="telMovil"
            type="tel"
            className={classes.textareaNE}
            placeholder="5555555555"
            defaultValue={props.estudiante.TelMovil}
            onValueChange={(values) => {
              setTelMovilInput(values.floatValue);
            }}
            onChange={setHayCambios}
          ></NumericFormat>
        </div>
        <div>
          <label className={classes.labelP}>e-mail</label>
          <input
            id="eMail"
            ref={eMailInputRef}
            type="email"
            className={classes.textareaNE + " " + classes.email}
            defaultValue={props.estudiante.Email}
            onChange={setHayCambios}
          ></input>
        </div>
        <div>
          <label className={classes.labelP}>Fecha Nacimiento</label>
          <input
            required
            id="fechaNac"
            ref={fechaNacInputRef}
            type="date"
            className={classes.textareaNE}
            defaultValue={FechaParaInputDate(props.estudiante.FechaNac)}
            onChange={setHayCambios}
          ></input>
        </div>
        <div>
          <label className={classes.tituloP + " " + classes.labelP}>
            Razón Estancia
          </label>
        </div>
        <div>
          <label className={classes.labelP}>Facultad</label>
          <input
            id="facultad"
            ref={facultadInputRef}
            type="text"
            className={classes.textareaNE}
            defaultValue={props.estudiante.Facultad}
            onChange={setHayCambios}
          ></input>
        </div>
        <div>
          <label className={classes.labelP}>Carrera</label>
          <input
            id="carrera"
            ref={carreraInputRef}
            type="text"
            className={classes.textareaNE}
            defaultValue={props.estudiante.Carrera}
            onChange={setHayCambios}
          ></input>
        </div>
        <div>
          <label className={classes.labelP}>Semestre</label>
          <NumericFormat
            required
            maxLength="2"
            id="semestre"
            type="text"
            className={classes.textareaNE}
            placeholder="1"
            defaultValue={props.estudiante.Semestre}
            onValueChange={(values) => {
              setSemestreInput(values.floatValue);
            }}
            onChange={setHayCambios}
          ></NumericFormat>
        </div>
        <div>
          <label className={classes.labelP}>Otros Estudios</label>
          <input
            id="otrosEst"
            ref={otrosEstInputRef}
            type="text"
            className={classes.textareaNE}
            placeholder="Cursando actualmente"
            defaultValue={props.estudiante.OtroMotivo}
            onChange={setHayCambios}
          ></input>
        </div>
        <div>
          <label className={classes.tituloP + " " + classes.labelP}>
            Documentación
          </label>
        </div>
        <div>
          <label className={classes.labelP}>Identificación</label>
          <select
            id="identificacion"
            ref={identificacionInputRef}
            className={classes.textareaNE}
            defaultValue={props.estudiante.Ident}
            onChange={setHayCambios}
          >
            <option key="0" value={"0"}>
              "¿Proporcionó copia?"
            </option>
            <option key="1" value={"1"}>
              Si
            </option>
            <option key="2" value={"2"}>
              No
            </option>
          </select>
        </div>
        <div>
          <label className={classes.labelP}>Cred. Estudiante</label>
          <select
            id="credEst"
            ref={credEstInputRef}
            className={classes.textareaNE}
            defaultValue={props.estudiante.CredEst}
            onChange={setHayCambios}
          >
            <option key="0" value={"0"}>
              "¿Proporcionó copia?"
            </option>
            <option key="1" value={"1"}>
              Si
            </option>
            <option key="2" value={"2"}>
              No
            </option>
          </select>
        </div>
        <div>
          <label className={classes.labelP}>Comp. Domicilio</label>
          <select
            id="compDom"
            ref={compDomInputRef}
            className={classes.textareaNE}
            defaultValue={props.estudiante.CompDom}
            onChange={setHayCambios}
          >
            <option key="0" value={"0"}>
              "¿Proporcionó copia?"
            </option>
            <option key="1" value={"1"}>
              Si
            </option>
            <option key="2" value={"2"}>
              No
            </option>
          </select>
        </div>
        <div>
          <label className={classes.labelP}>Reglamento</label>
          <select
            id="reglamento"
            ref={reglamentoInputRef}
            className={classes.textareaNE}
            defaultValue={props.estudiante.Reglam}
            onChange={setHayCambios}
          >
            <option key="0" value={"0"}>
              "¿Lo firmó?"
            </option>
            <option key="1" value={"1"}>
              Si
            </option>
            <option key="2" value={"2"}>
              No
            </option>
          </select>
        </div>
        <div>
          <label className={classes.tituloP + " " + classes.labelP}>
            Fechas
          </label>
        </div>
        <div>
          <label className={classes.labelP}>Fecha Ingreso</label>
          <input
            required
            id="fechaIng"
            ref={fechaIngInputRef}
            type="date"
            className={classes.textareaNE}
            defaultValue={FechaParaInputDate(props.estudiante.FechaIng)}
            onChange={setHayCambios}
          ></input>
        </div>
        <div>
          <label className={classes.labelP}>Inicia Contrato</label>
          <input
            required
            id="iniciaCon"
            ref={iniciaConInputRef}
            type="date"
            className={classes.textareaNE}
            defaultValue={FechaParaInputDate(props.estudiante.IniciaCont)}
            onChange={setHayCambios}
          ></input>
        </div>
        <div>
          <label className={classes.labelP}>Vence Contrato</label>
          <input
            required
            id="venceCon"
            ref={venceConInputRef}
            type="date"
            className={classes.textareaNE}
            defaultValue={FechaParaInputDate(props.estudiante.VenceCont)}
            onChange={setHayCambios}
          ></input>
        </div>
        <div>
          <label className={classes.labelP}>Día de corte</label>
          <NumericFormat
            required
            maxLength="2"
            id="diaCorte"
            type="text"
            className={classes.textareaNE}
            placeholder="1"
            defaultValue={props.estudiante.DiaCorte}
            onValueChange={(values) => {
              setDiaCorteInput(values.floatValue);
            }}
            onChange={setHayCambios}
          ></NumericFormat>
        </div>
        <div>
          <label className={classes.tituloP + " " + classes.labelP}>
            Contacto 1
          </label>
        </div>
        <div>
          <label className={classes.labelP}>Parentesco</label>
          <input
            id="parentesco1"
            ref={parentesco1InputRef}
            type="text"
            className={classes.textareaNE}
            placeholder="Madre/Padre/Hermano/Tio"
            defaultValue={props.estudiante.Contac1}
            onChange={setHayCambios}
          ></input>
        </div>
        <div>
          <label className={classes.labelP}>Nombre</label>
          <input
            id="nombre1"
            ref={nombre1InputRef}
            type="text"
            className={classes.textareaNE}
            defaultValue={props.estudiante.NombreC1}
            onChange={setHayCambios}
          ></input>
        </div>
        <div>
          <label className={classes.labelP}>Direccion</label>
          <input
            id="direccion1"
            ref={direccion1InputRef}
            type="text"
            className={classes.textareaNE}
            defaultValue={props.estudiante.DireccC1}
            onChange={setHayCambios}
          ></input>
        </div>
        <div>
          <label className={classes.labelP}>e-mail</label>
          <input
            id="eMail1"
            type="email"
            ref={eMail1InputRef}
            className={classes.textareaNE + " " + classes.email}
            defaultValue={props.estudiante.EmailC1}
            placeholder="correo@correo.com"
            onChange={setHayCambios}
          ></input>
        </div>
        <div>
          <label className={classes.labelP}>Teléfono móvil</label>
          <NumericFormat
            maxLength="20"
            id="telMovil1"
            type="tel"
            className={classes.textareaNE}
            placeholder="5555555555"
            defaultValue={props.estudiante.TelMobiC1}
            onValueChange={(values) => {
              setTelMovil1Input(values.floatValue);
            }}
            onChange={setHayCambios}
          ></NumericFormat>
        </div>
        <div>
          <label className={classes.tituloP + " " + classes.labelP}>
            Contacto 2
          </label>
        </div>
        <div>
          <label className={classes.labelP}>Parentesco</label>
          <input
            id="parentesco2"
            ref={parentesco2InputRef}
            type="text"
            className={classes.textareaNE}
            placeholder="Madre/Padre/Hermano/Tio"
            defaultValue={props.estudiante.Contac2}
            onChange={setHayCambios}
          ></input>
        </div>
        <div>
          <label className={classes.labelP}>Nombre</label>
          <input
            id="nombre2"
            ref={nombre2InputRef}
            type="text"
            className={classes.textareaNE}
            defaultValue={props.estudiante.NombreC2}
            onChange={setHayCambios}
          ></input>
        </div>
        <div>
          <label className={classes.labelP}>Direccion</label>
          <input
            id="direccion2"
            ref={direccion2InputRef}
            type="text"
            className={classes.textareaNE}
            defaultValue={props.estudiante.DireccC2}
            onChange={setHayCambios}
          ></input>
        </div>
        <div>
          <label className={classes.labelP}>e-mail</label>
          <input
            id="eMail2"
            type="email"
            ref={eMail2InputRef}
            className={classes.textareaNE + " " + classes.email}
            defaultValue={props.estudiante.EmailC2}
            placeholder="correo@correo.com"
            onChange={setHayCambios}
          ></input>
        </div>
        <div>
          <label className={classes.labelP}>Teléfono móvil</label>
          <NumericFormat
            maxLength="20"
            id="telMovil2"
            type="tel"
            className={classes.textareaNE}
            placeholder="5555555555"
            defaultValue={props.estudiante.TelMobiC2}
            onValueChange={(values) => {
              setTelMovil2Input(values.floatValue);
            }}
            onChange={setHayCambios}
          ></NumericFormat>
        </div>
        <div>
          <label className={classes.tituloP + " " + classes.labelP}>
            Habitación
          </label>
        </div>
        <div>
          <label className={classes.labelP}>Habitación</label>
          <input
            id="habitacion"
            ref={habitacionInputRef}
            type="text"
            defaultValue={props.estudiante.Habitacion}
            className={classes.textHab}
          ></input>
          <button onClick={cambiaHabitacion} id={props.estudiante.IDEst}>
            <i className="fas fa-edit"></i>
          </button>
        </div>
        <div>
          <label className={classes.tituloP + " " + classes.labelP}>
            Llave
          </label>
        </div>
        <div className={classes.contenedorSelect}>
          <Select
            isMulti={true}
            value={llaveSeleccionada}
            onChange={handleSelect}
            className={classes.textareaNE}
            options={loadedLlaves.map((llave) => {
              return { value: llave.IDLlave, label: llave.Llave };
            })}
          />
        </div>
        <div>
          <label className={classes.tituloP + " " + classes.labelP}>
            Otros
          </label>
        </div>
        <div>
          <label className={classes.labelP}>% por pago tardío</label>
          <NumericFormat
            maxLength="4"
            id="porcPagoTardio"
            type="text"
            className={classes.textareaNE}
            placeholder="5%"
            defaultValue={props.estudiante.PorcPagoT}
            value={porcPagoTardioInput}
            suffix={"%"}
            thousandsGroupStyle="thousand"
            thousandSeparator=","
            decimalScale={0}
            onValueChange={(values) => {
              setPorcPagoTardioInput(values.floatValue);
            }}
            onChange={setHayCambios}
          ></NumericFormat>
        </div>
        <div>
          <label className={classes.labelP}>Días para pago tardío</label>
          <NumericFormat
            maxLength="10"
            id="diasPagoTardio"
            type="text"
            className={classes.textareaNE}
            placeholder="5 días"
            defaultValue={props.estudiante.DiasPagoT}
            value={diasPagoTardioInput}
            suffix={" día(s)"}
            decimalScale={0}
            onValueChange={(values) => {
              setDiasPagoTardioInput(values.floatValue);
            }}
            onChange={setHayCambios}
          ></NumericFormat>
        </div>
        <div>
          <label className={classes.labelP}>% pago habitación</label>
          <NumericFormat
            maxLength="4"
            id="porcPagoHab"
            type="text"
            className={classes.textareaNE}
            placeholder="100%"
            defaultValue={props.estudiante.PorcPagoH}
            value={porcPagoHabitacionInput}
            suffix={"%"}
            decimalScale={2}
            onValueChange={(values) => {
              setPorcPagoHabitacionInput(values.floatValue);
            }}
            onChange={setHayCambios}
          ></NumericFormat>
        </div>
        <div>
          <label className={classes.labelP}>Desc pago en Efectivo</label>
          <NumericFormat
            maxLength="8"
            id="descPagoEfe"
            type="text"
            className={classes.textareaNE}
            placeholder="$100.00"
            defaultValue={props.estudiante.DescPagoE}
            value={descPagEfeInput}
            prefix={"$"}
            decimalScale={2}
            thousandsGroupStyle="thousand"
            thousandSeparator=","
            onValueChange={(values) => {
              setDescPagEfeInput(values.floatValue);
            }}
            onChange={setHayCambios}
          ></NumericFormat>
        </div>
        <div>
          <label className={classes.labelP}>Nombre planilla fotos</label>
          <input
            id="nombreFoto"
            ref={nombrefotoInputRef}
            type="text"
            className={classes.textareaNE}
            defaultValue={props.estudiante.NombreFoto}
            onChange={setHayCambios}
          ></input>
        </div>
        <div>
          <label className={classes.tituloP + " " + classes.labelP}>
            Observaciones
          </label>
        </div>
        <div>
          <label className={classes.labelP}>Observaciones</label>
          <input
            id="observaciones"
            ref={observacionesRef}
            type="text"
            className={classes.textareaNE}
            defaultValue={props.estudiante.Observaciones}
            onChange={setHayCambios}
          ></input>
        </div>
        <div>
          <label className={classes.tituloP + " " + classes.labelP}>
            Identificación
          </label>
        </div>
        <div>
          <img
            key={timeStamp}
            className={classes.otrasFotos}
            src={
              "https://www.copilco.net/expedientes/documentos/" +
              props.estudiante.IDEst +
              "_ID.jpg?" +
              timeStamp
            }
            onError={(e) => {
              e.target.onerror = null;
              e.target.src =
                "https://www.copilco.net/expedientes/documentos/defaultDOC.jpg";
            }}
            alt={props.estudiante.Nombre}
          />
          <button
            onClick={cambiaIDEstudiante}
            id={props.estudiante.IDEst}
            className={classes.innbtn2}
          >
            <i className="fas fa-edit"></i>
          </button>
        </div>
        <div>
          <label className={classes.tituloP + " " + classes.labelP}>
            Credencial Estudiante
          </label>
        </div>
        <div>
          <img
            key={timeStamp}
            className={classes.otrasFotos}
            src={
              "https://www.copilco.net/expedientes/documentos/" +
              props.estudiante.IDEst +
              "_CredencialEstudiante.jpg?" +
              timeStamp
            }
            onError={(e) => {
              e.target.onerror = null;
              e.target.src =
                "https://www.copilco.net/expedientes/documentos/defaultDOC.jpg";
            }}
            alt={props.estudiante.Nombre}
          />
          <button
            onClick={cambiaCEEstudiante}
            id={props.estudiante.IDEst}
            className={classes.innbtn2}
          >
            <i className="fas fa-edit"></i>
          </button>
        </div>
        <div>
          <label className={classes.tituloP + " " + classes.labelP}>
            Comprobante Domicilio
          </label>
        </div>
        <div>
          <img
            key={timeStamp}
            className={classes.otrasFotos}
            src={
              "https://www.copilco.net/expedientes/documentos/" +
              props.estudiante.IDEst +
              "_ComprobanteDomicilio.jpg?" +
              timeStamp
            }
            onError={(e) => {
              e.target.onerror = null;
              e.target.src =
                "https://www.copilco.net/expedientes/documentos/defaultDOC.jpg";
            }}
            alt={props.estudiante.Nombre}
          />
          <button
            onClick={cambiaCDEstudiante}
            id={props.estudiante.IDEst}
            className={classes.innbtn2}
          >
            <i className="fas fa-edit"></i>
          </button>
        </div>
        <div>
          <label className={classes.tituloP + " " + classes.labelP}>
            Depósito en Garantía
          </label>
        </div>
        <div>
          <img
            key={timeStamp}
            className={classes.otrasFotos}
            src={
              "https://www.copilco.net/expedientes/documentos/" +
              props.estudiante.IDEst +
              "_DepositoGarantia.jpg?" +
              timeStamp
            }
            onError={(e) => {
              e.target.onerror = null;
              e.target.src =
                "https://www.copilco.net/expedientes/documentos/defaultDOC.jpg";
            }}
            alt={props.estudiante.Nombre}
          />
          <button
            onClick={cambiaDGEstudiante}
            id={props.estudiante.IDEst}
            className={classes.innbtn2}
          >
            <i className="fas fa-edit"></i>
          </button>
        </div>
        <div>
          <label className={classes.tituloP + " " + classes.labelP}>
            Contrato
          </label>
        </div>

        <div>
          <button
            className={classes.innbtn2}
            onClick={(ev) => {
              ev.preventDefault();
              muestraContrato();
            }}
          >
            Ver
          </button>
          {
            <button
              onClick={renuevaContratoEstudiante}
              id={props.estudiante.IDEst}
              className={classes.innbtn2}
            >
              Renovar
            </button>
          }
          <button
            onClick={cambiaContratoEstudiante}
            id={props.estudiante.IDEst}
            className={classes.innbtn2}
          >
            Cargar
          </button>
          <button
            onClick={regeneraContratoEstudiante}
            id={props.estudiante.IDEst}
            className={classes.innbtn2}
          >
            Regenerar
          </button>
        </div>
        <div className={classes.linea}></div>

        {muestraDialogoOK && (
          <OKModal
            texto={dialogoOK.texto}
            oculta={contesta}
            botones={dialogoOK.botones}
          />
        )}
        {muestraDialogoOK && <Backdrop zindex={49} />}
      </form>
    </section>
  );
}

export default EditaEstudiante;
