import { useState, useEffect } from "react";

import EstudianteLista from "../components/estudiantes/EstudianteLista";
import useToken from "../components/login/useToken";
import NuevoEstudiante from "../components/estudiantes/EstudianteNuevo";
import Backdrop from "../components/layout/backdrop";
import DescuentoEstudiante from "../components/estudiantes/EstudianteDescuento";
import NuevoIngreso from "../components/Ingresos/IngresoNuevo";
import SubMenuEstudiantes from "../components/estudiantes/SubMenuEstudiantes";
import EliminaEstudiante from "../components/estudiantes/EstudianteElimina";
import EditaEstudiante from "../components/estudiantes/EstudianteEdicion";
import { addMonths } from "date-fns";
import MenuFiltraEstudiantes from "../components/estudiantes/EstudianteFiltroMenu";
import MenuOrdenaEstudiantes from "../components/estudiantes/EstudianteOrdenaMenu";
import PagosEstudiante from "../components/Ingresos/PagosEstudiante";
import CambiaHabitacion from "../components/habitaciones/HabitacionCambia";
import OKModal from "../components/dialogos/dialogoOK";
import Reactivaestudiante from "../components/estudiantes/EstudianteReactiva";
import AdeudoEstudiante from "../components/estudiantes/EstudianteAdeudo";
import ListaNegra from "../components/estudiantes/EstudianteListaNegra";

function TodosLosEstudiantesPage(props) {
  const [isLoading, setIsLoading] = useState(true);
  const [loadedEstudiantes, setLoadedEstudiantes] = useState([]);
  const [loadedHabitaciones, setLoadedHabitaciones] = useState([]);
  const [mostrarNuevo, setMostrarNuevo] = useState(false);
  const [mostrarDescuentos, setMostrarDescuentos] = useState(false);
  const [mostrarPago, setMostrarPago] = useState(false);
  const [mostrarEdicion, setMostrarEdicion] = useState(false);
  const [estudiante, setEstudiante] = useState([]);
  const [muestraSubMenu, setMuestraSubMenu] = useState(false);
  const [posicionSubMenu, setPosicionSubMenu] = useState([]);
  const [mostrarElimina, setMostrarElimina] = useState(false);
  const [loadedDepositos, setLoadedDepositos] = useState([]);
  const [loadedUltPagos, setLoadedUltPagos] = useState([]);
  const [muestraFiltroEst, setMuestraFiltroEst] = useState(false);
  const [posFiltroEstudiantes, setPosFiltroEstudiantes] = useState([]);
  const [filtroEstudiantes, setFiltroEstudiantes] = useState({
    conAdeudo: true,
    sinAdeudo: true,
    porNombre: "",
    porHabitacion: "",
  });
  const [muestraOrdenaEst, setMuestraOrdenaEst] = useState(false);
  const [posOrdenaEstudiantes, setPosOrdenaEstudiantes] = useState([]);
  const [ordenaEstudiantes, setOrdenaEstudiantes] = useState({
    NombreAZ: false,
    NombreZA: false,
    HabAZ: true,
    HabZA: false,
    AdeudaAZ: false,
    AdeudaZA: false,
  });
  const [mostrarPagosEstudiante, setMostrarPagosEstudiante] = useState(false);
  const [datosPagosEstudiante, setDatosPagosEstudiante] = useState({});
  const [mostrarCambiaHabitacion, setMostrarCambiaHabitacion] = useState(false);
  const [datosCambiaHabitacion, setDatosCambiaHabitacion] = useState({});
  const [borrado, setBorrado] = useState([false]);
  const [recargaFoto, setRecargaFoto] = useState(false);
  const { token } = useToken();
  const [dialogoOK, setDialogoOK] = useState({
    texto: "",
    mostrandoError: false,
    recargar: false,
    cerrarVentana: "",
  });
  const [muestraDialogoOK, setMuestraDialogoOK] = useState(false);
  const [recargar, setRecargar] = useState(false);
  const [muestraReactivaEstudiante, setMuestraReactivaEstudiante] =
    useState(false);
  const [muestraAdeudo, setMuestraAdeudo] = useState(false);
  const [datosPopAdeudo, setDatosPopAdeudo] = useState([]);
  const [muestraListaNegra, setMuestraListaNegra] = useState(false);

  //console.log("TodoslosEst",props);
  function preparaAdeudo(datosAdeudo) {
    setDatosPopAdeudo(datosAdeudo);
    setMuestraAdeudo(true);
  }

  function palomeaOK() {
    setMuestraDialogoOK(false);
    if (!dialogoOK.mostrandoError) {
      if (dialogoOK.cerrarVentana === "mostrarDescuentos")
        setMostrarDescuentos(false);
      if (dialogoOK.cerrarVentana === "mostrarEdicion")
        setMostrarEdicion(false);
      if (dialogoOK.cerrarVentana === "mostrarElimina")
        setMostrarElimina(false);
      if (dialogoOK.cerrarVentana === "NuevoEstudiante") setMostrarNuevo(false);
      if (dialogoOK.cerrarVentana === "cambiaHabitacion")
        setMostrarCambiaHabitacion(false);
      if (dialogoOK.cerrarVentana === "pagoNuevo") setMostrarPago(false);
      if (dialogoOK.cerrarVentana === "ReactivaEstudiante")
        setMuestraReactivaEstudiante(false);
      if (dialogoOK.recargar) {
        setRecargar(true);
        props.setRecargar(true);
        setDialogoOK({ recargar: false });
      }
    }
  }

  //console.log("filtroEstudiantes", filtroEstudiantes);
  function PagoCompletado() {
    setMostrarNuevo(false);
    setBorrado(true);
    setRecargar(true);
    if (props.setRecargar !== undefined) {
      props.setRecargar(true);
    }
  }

  function EliminadoOK() {
    setMostrarElimina(false);
    setBorrado(true);
  }
  function AgregaEstudiante() {
    setMostrarNuevo(true);
  }

  function ReactivaEstudiante() {
    setMuestraReactivaEstudiante(true);
  }

  function QueEstudiantesMandar(estudiante) {
    if (props?.muestra !== "Adeudo") {
      if (!filtroEstudiantes) return true;
      if (filtroEstudiantes.porHabitacion !== "")
        if (
          estudiante.Habitacion.toUpperCase().indexOf(
            filtroEstudiantes.porHabitacion.toUpperCase()
          ) < 0
        )
          return false;
      if (filtroEstudiantes.porNombre !== "")
        if (
          estudiante.Nombre.toUpperCase().indexOf(
            filtroEstudiantes.porNombre.toUpperCase()
          ) < 0
        )
          return false;
    }
    if (filtroEstudiantes.conAdeudo && estudiante.Adeudo !== "0") return true;
    if (props?.muestra !== "Adeudo") {
      if (filtroEstudiantes.sinAdeudo && estudiante.Adeudo === "0") return true;
    }
    return false;
  }

  function OrdenEstudiantes(a, b) {
    if (props.muestra === "Adeudo") {
      const adeudoA =
        a.Adeudo.substring(0, a.Adeudo.indexOf("(") - 1) +
        (a.DiaCorte.length === 1 ? +" " + a.DiaCorte : a.DiaCorte);
      const adeudoB =
        b.Adeudo.substring(0, b.Adeudo.indexOf("(") - 1) +
        (b.DiaCorte.length === 1 ? +" " + b.DiaCorte : b.DiaCorte);
      return parseInt(adeudoA) > parseInt(adeudoB) ? 1 : -1;
    }
    if (ordenaEstudiantes.NombreZA) return a.Nombre < b.Nombre ? 1 : -1;
    if (ordenaEstudiantes.HabAZ)
      return a.Habitacion.toUpperCase() > b.Habitacion.toUpperCase() ? 1 : -1;
    if (ordenaEstudiantes.HabZA)
      return a.Habitacion.toUpperCase() < b.Habitacion.toUpperCase() ? 1 : -1;
    if (ordenaEstudiantes.AdeudaAZ)
      return parseInt(TotalAdeudo(a)) > parseInt(TotalAdeudo(b)) ? 1 : -1;
    if (ordenaEstudiantes.AdeudaZA)
      return parseInt(TotalAdeudo(a)) < parseInt(TotalAdeudo(b)) ? 1 : -1;
    return a.Nombre > b.Nombre ? 1 : -1;
  }

  function TotalAdeudo(estudiante) {
    if (estudiante.Adeudo === undefined) return 0;
    if (estudiante.Adeudo === "0") return 0;
    var adeudo = estudiante.Adeudo.substring(
      0,
      estudiante.Adeudo.indexOf(")") + 1
    );
    if (adeudo.length !== estudiante.Adeudo.length) {
      var temp = estudiante.Adeudo + ",";
      var total = 0;
      while (temp.indexOf(",") > -1) {
        total =
          parseInt(total) +
          parseFloat(temp.substring(temp.indexOf("(") + 1, temp.indexOf(")")));
        temp = temp.substr(temp.indexOf(",") + 1);
      }

      adeudo = "ene 2021 (" + total + ")";
    }
    return adeudo.substring(adeudo.indexOf("(") + 1, adeudo.indexOf(")"));
  }

  function FiltraEstudiantes(ev) {
    //console.log("ev", ev.target);
    setPosFiltroEstudiantes({
      x: ev.target.offsetLeft,
      y: ev.target.offsetTop + ev.target.offsetHeight,
    });
    setMuestraFiltroEst(true);
  }

  function OrdenaEstudiantes(ev) {
    setPosOrdenaEstudiantes({
      x: ev.target.offsetLeft,
      y: ev.target.offsetTop + ev.target.offsetHeight,
    });
    setMuestraOrdenaEst(true);
  }

  function AgregaDescuentoHandler(estudiante) {
    setEstudiante(estudiante);
    setMostrarDescuentos(true);
  }

  function setDatosSubMenu(datos) {
    setPosicionSubMenu({
      posX: datos.posX,
      posY: datos.posY,
    });
    setEstudiante(datos.estudiante);
  }

  function EditaEstudianteHandler(estudiante) {
    setEstudiante(estudiante);
    setMostrarEdicion(true);
  }

  function muestraPagosEstudiante(IDEst) {
    setMuestraSubMenu(false);
    const tempEst = loadedEstudiantes.find((est) => est.IDEst === IDEst);
    setDatosPagosEstudiante({
      IDEst: IDEst,
      DiaCorte: tempEst.DiaCorte,
      Nombre: tempEst.Nombre,
    });
    setMostrarPagosEstudiante(true);
  }

  function cambiaHabitacion(IDEst) {
    setMuestraSubMenu(false);
    setDatosCambiaHabitacion({ IDEst: IDEst });
    setMostrarCambiaHabitacion(true);
  }

  function EliminaEstudianteHandler(estudiante) {
    //buscamos el deposito del estudiante
    for (const dep of loadedDepositos) {
      if (dep.IDEst === estudiante.IDEst) {
        estudiante.Deposito = dep.Deposito;
        break;
      }
    }
    //buscamos el ultimo pago del estudiante
    for (const ultp of loadedUltPagos) {
      if (ultp.IDEst === estudiante.IDEst) {
        //console.log(ultp);
        var fechaT = new Date(
          parseInt(estudiante.FechaIng.slice(0, 4)),
          parseInt(estudiante.FechaIng.slice(4, 6)) - 1,
          parseInt(estudiante.FechaIng.slice(6, 8))
        );
        //console.log(fechaT, addMonths(fechaT, 1));
        estudiante.UltDiaPagado = addMonths(fechaT, 1);
        if (ultp.MesQPag) {
          fechaT = new Date(
            ultp.MesQPag.slice(0, 4),
            parseInt(ultp.MesQPag.slice(4, 6)) - 1,
            ultp.DiaCorte
          );
          //console.log(fechaT);
          estudiante.UltDiaPagado = addMonths(fechaT, 1);
        }
        estudiante.UltPeriodoAbarca = CalculaPeriodo(estudiante.UltDiaPagado);
        break;
      }
    }
    //console.log(estudiante);
    setEstudiante(estudiante);
    setMostrarElimina(true);
  }

  function CalculaPeriodo(fecha) {
    if (fecha) {
      var mes = parseInt(fecha.getMonth()) + 1;
      if (mes.toString().length === 1) mes = "0" + mes;
      var dia = fecha.getDate();
      if (dia.toString().length === 1) dia = "0" + dia;
      const FechaFin = dia + "-" + mes + "-" + fecha.getFullYear();
      const fechaF = addMonths(fecha, -1);
      mes = parseInt(fechaF.getMonth()) + 1;
      if (mes.toString().length === 1) mes = "0" + mes;
      dia = fechaF.getDate();
      if (dia.toString().length === 1) dia = "0" + dia;
      const FechaIni = dia + "-" + mes + "-" + fechaF.getFullYear();
      return FechaIni + " al " + FechaFin;
    }
  }

  function AgregaPagoHandler(estudiante) {
    fetch("https://www.copilco.net/inc/funciones.React.php", {
      method: "POST",
      body: JSON.stringify({
        accion: "DatosAltaIngreso",
        IDCasa: props.casaSel,
        IDEst: estudiante.IDEst,
      }),
      headers: {
        "content-type": "application/json",
      },
    })
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        //console.log(data);
        var datosNece = [];
        if (data.ERROR !== "SI") {
          for (const key in data) {
            const datos = {
              ...data[key],
            };
            if (key === "Habitaciones") {
              datosNece = Object.keys(datos).map((key) => datos[key]);
            }
          }
          if (!datosNece[0].MesQPag) {
            //si no hay mes de pago, o sea, no ha pagado nunca
            //ponemos el mes que entro como fecha de pago inicial
            datosNece[0].MesQPag = datosNece[0].FechaIng.slice(0, 6);
          }
          const estT = {
            ...estudiante,
            MesQPag: datosNece[0].MesQPag + " (" + datosNece[0].Precio + ")",
          };
          setEstudiante(estT);
          setMostrarPago(true);
        } else {
          console.log("ERROR", data.ERROR);
        }
      })
      .catch((ev) => {
        console.log("error TE", ev);
      });
  }

  useEffect(() => {
    setMostrarEdicion(false);
    setMostrarCambiaHabitacion(false);
    setMostrarDescuentos(false);
    setMostrarElimina(false);
    setMostrarNuevo(false);
    setMostrarPago(false);
    setMostrarPagosEstudiante(false);
    setMuestraFiltroEst(false);
    setMuestraOrdenaEst(false);
    setMuestraReactivaEstudiante(false);
    setMuestraSubMenu(false);
    setMuestraDialogoOK(false);
    setMuestraAdeudo(false);
    if (props.casaSel > 0) {
      setBorrado(false);
      setRecargar(false);
      setIsLoading(true);
      fetch("https://www.copilco.net/inc/funciones.React.php", {
        method: "POST",
        body: JSON.stringify({
          accion: "ResumenEstudiantes",
          IDCasa: props.casaSel,
        }),
        headers: {
          "content-type": "application/json",
        },
      })
        .then((response) => {
          return response.json();
        })
        .then((data) => {
          var datosResumen = {};
          var estudiantes = [];
          var habitaciones = [];
          var depositos = [];
          var ultpagos = [];
          if (data.ERROR !== "SI") {
            for (const key in data) {
              const datos = {
                ...data[key],
              };
              datosResumen = { ...datosResumen, [key]: datos };
              if (key === "RInquilinos") {
                estudiantes = Object.keys(datos).map((key) => datos[key].Datos);
              } else if (key === "RHabitaciones") {
                habitaciones = Object.keys(datos).map((key) => datos[key]);
              } else if (key === "Deposito") {
                depositos = Object.keys(datos).map((key) => datos[key]);
              } else if (key === "UltimoPago") {
                ultpagos = Object.keys(datos).map((key) => datos[key]);
              }
            }
            //console.log("estudiantes", estudiantes);
            setIsLoading(false);
            setLoadedDepositos(depositos);
            setLoadedEstudiantes(estudiantes);
            setLoadedHabitaciones(habitaciones);
            setLoadedUltPagos(ultpagos);
          } else {
            setLoadedDepositos([]);
            setLoadedEstudiantes([]);
            setLoadedHabitaciones([]);
            setLoadedUltPagos([]);
            setIsLoading(false);
            console.log("ERROR", data.ERROR);
          }
        });
    } else {
      setIsLoading(false);
    }
  }, [props.casaSel, token, borrado, recargar]);

  function recarga() {
    setMuestraSubMenu(false);
  }
  console.log("recargafoto", recargaFoto, recargar);
  /***********************************************************************/
  /************************ RENDER ***************************************/
  /***********************************************************************/
  if (isLoading) {
    return (
      <section>
        <h1>Cargando estudiantes...</h1>
        <h1>
          <i className="fas fa-spinner fa-spin"></i>
        </h1>
      </section>
    );
  }
  if (props.casaSel > 0 && loadedEstudiantes) {
    if (loadedEstudiantes.length > 0) {
      return (
        <section className="centrarElem">
          {props?.muestra !== "Adeudo" ? (
            <div className="TituloBoton">
              <h1 className="TitEst">Estudiantes</h1>
              <div className="contBotones">
                <button className="botAgregaEst" onClick={OrdenaEstudiantes}>
                  <i className="fas fa-sort-amount-up-alt"></i>
                </button>
                <button className="botAgregaEst" onClick={FiltraEstudiantes}>
                  <i className="fas fa-filter"></i>
                </button>
                {props.nivel === 1 ? (
                  <button className="botAgregaEst" onClick={AgregaEstudiante}>
                    <i className="fas fa-user-plus"></i>
                  </button>
                ) : null}
                {props.nivel === 1 ? (
                  <button className="botAgregaEst" onClick={ReactivaEstudiante}>
                    <i className="fas fa-users-cog"></i>
                  </button>
                ) : null}
                <button className="botAgregaEst" onClick={setMuestraListaNegra}>
                  <i className="fas fa-ban"></i>
                </button>
              </div>
            </div>
          ) : null}
          {loadedEstudiantes.filter((estudiante) =>
            QueEstudiantesMandar(estudiante)
          ).length === 0 && <h1>Sin Estudiantes por Filtro</h1>}
          <EstudianteLista
            AgregaPagoHandler={AgregaPagoHandler}
            AgregaDescuentoHandler={AgregaDescuentoHandler}
            setMuestraSubMenu={setMuestraSubMenu}
            setDatosSubMenu={setDatosSubMenu}
            EliminaEstudianteHandler={EliminaEstudianteHandler}
            EditaEstudianteHandler={EditaEstudianteHandler}
            estudiantes={loadedEstudiantes
              .filter((estudiante) => QueEstudiantesMandar(estudiante))
              .sort((a, b) => OrdenEstudiantes(a, b))}
            habitaciones={loadedHabitaciones}
            recargaFoto={recargaFoto}
            setRecargaFoto={setRecargaFoto}
            preparaAdeudo={preparaAdeudo}
            nivel={props.nivel}
            muestra={props.muestra ? props.muestra : ""}
          />
          {mostrarNuevo && (
            <NuevoEstudiante
              estudiantes={loadedEstudiantes}
              setLoadedEstudiantes={setLoadedEstudiantes}
              setMostrarNuevo={setMostrarNuevo}
              setDialogoOK={setDialogoOK}
              setMuestraDialogoOK={setMuestraDialogoOK}
              habitaciones={loadedHabitaciones}
              setRecargar={setRecargar}
              nivel={props.nivel}
            />
          )}
          {mostrarDescuentos && (
            <DescuentoEstudiante
              setMostrarDescuentos={setMostrarDescuentos}
              estudiante={estudiante}
              setDialogoOK={setDialogoOK}
              setMuestraDialogoOK={setMuestraDialogoOK}
              nivel={props.nivel}
              setRecargar={setRecargar}
            />
          )}
          {mostrarPago && (
            <NuevoIngreso
              pagoNuevo={estudiante}
              PagoCompletado={PagoCompletado}
              setMostrarNuevo={setMostrarPago}
              setDialogoOK={setDialogoOK}
              setMuestraDialogoOK={setMuestraDialogoOK}
              nivel={props.nivel}
            />
          )}
          {mostrarElimina && (
            <EliminaEstudiante
              ultpagos={loadedUltPagos}
              estudiante={estudiante}
              setMostrarElimina={setMostrarElimina}
              EliminadoOK={EliminadoOK}
              setDialogoOK={setDialogoOK}
              setMuestraDialogoOK={setMuestraDialogoOK}
              nivel={props.nivel}
            />
          )}
          {mostrarEdicion && (
            <EditaEstudiante
              estudiante={estudiante}
              setMostrarEdicion={setMostrarEdicion}
              setBorrado={setBorrado}
              setRecargaFoto={setRecargaFoto}
              setDialogoOK={setDialogoOK}
              setMuestraDialogoOK={setMuestraDialogoOK}
              setMostrarElimina={setMostrarElimina}
              nivel={props.nivel}
              setRecargar={setRecargar}
            />
          )}
          {muestraSubMenu && (
            <SubMenuEstudiantes
              posicionSubMenu={posicionSubMenu}
              estudiante={estudiante}
              muestraPagosEstudiante={muestraPagosEstudiante}
              cambiaHabitacion={cambiaHabitacion}
              nivel={props.nivel}
            />
          )}
          {(mostrarEdicion ||
            mostrarElimina ||
            mostrarPago ||
            mostrarNuevo ||
            mostrarDescuentos) && <Backdrop />}
          {muestraFiltroEst && (
            <MenuFiltraEstudiantes
              posicionFiltroEstudiantes={posFiltroEstudiantes}
              setFiltroEstudiantes={setFiltroEstudiantes}
              filtroEstudiantes={filtroEstudiantes}
              setMuestraFiltroEst={setMuestraFiltroEst}
            />
          )}
          {muestraOrdenaEst && (
            <MenuOrdenaEstudiantes
              posicionOrdenaEstudiantes={posOrdenaEstudiantes}
              setOrdenaEstudiantes={setOrdenaEstudiantes}
              ordenaEstudiantes={ordenaEstudiantes}
              setMuestraOrdenaEst={setMuestraOrdenaEst}
            />
          )}
          {mostrarPagosEstudiante && (
            <PagosEstudiante
              datosPagosEstudiante={datosPagosEstudiante}
              setMostrarPagosEstudiante={() => setMostrarPagosEstudiante(false)}
              borrado={borrado}
              setBorrado={setBorrado}
              setDialogoOK={setDialogoOK}
              setMuestraDialogoOK={setMuestraDialogoOK}
              nivel={props.nivel}
            />
          )}
          {mostrarCambiaHabitacion && (
            <CambiaHabitacion
              datosCambiaHabitacion={datosCambiaHabitacion}
              setMostrarCambiaHabitacion={() =>
                setMostrarCambiaHabitacion(false)
              }
              borrado={borrado}
              setBorrado={setBorrado}
              setDialogoOK={setDialogoOK}
              setMuestraDialogoOK={setMuestraDialogoOK}
            />
          )}

          {muestraReactivaEstudiante && (
            <Reactivaestudiante
              setMuestraReactivaEstudiante={setMuestraReactivaEstudiante}
              setDialogoOK={setDialogoOK}
              setMuestraDialogoOK={setMuestraDialogoOK}
              setRecargar={setRecargar}
              nivel={props.nivel}
            />
          )}
          {muestraReactivaEstudiante && (
            <Backdrop recarga={setMuestraReactivaEstudiante} />
          )}
          {mostrarPagosEstudiante && (
            <Backdrop recarga={setMostrarPagosEstudiante} />
          )}
          {mostrarCambiaHabitacion && (
            <Backdrop recarga={setMostrarCambiaHabitacion} />
          )}
          {muestraOrdenaEst && <Backdrop recarga={setMuestraOrdenaEst} />}
          {muestraFiltroEst && <Backdrop recarga={setMuestraFiltroEst} />}
          {muestraSubMenu && <Backdrop recarga={recarga} />}
          {muestraDialogoOK && (
            <OKModal texto={dialogoOK.texto} oculta={palomeaOK} />
          )}
          {muestraDialogoOK && <Backdrop zindex={49} />}
          {muestraAdeudo && (
            <AdeudoEstudiante
              setMuestraAdeudo={setMuestraAdeudo}
              datosPopAdeudo={datosPopAdeudo}
            />
          )}
          {muestraAdeudo && <Backdrop recarga={setMuestraAdeudo} />}
          {muestraListaNegra && (
            <ListaNegra
              nivel={props.nivel}
              setMuestraListaNegra={setMuestraListaNegra}
              setRecargaFoto={setRecargaFoto}
            />
          )}
          {muestraListaNegra && <Backdrop />}
        </section>
      );
    } else {
      return (
        <section>
          <div className="TituloBoton">
            <h1 className="TitEst">No hay estudiantes</h1>
            <div className="contBotones">
              {props.nivel === 1 ? (
                <button className="botAgregaEst" onClick={AgregaEstudiante}>
                  <i className="fas fa-user-plus"></i>
                </button>
              ) : null}
              <button className="botAgregaEst" onClick={setMuestraListaNegra}>
                <i className="fas fa-ban"></i>
              </button>
            </div>
          </div>
          {mostrarNuevo && (
            <NuevoEstudiante
              estudiantes={loadedEstudiantes}
              setLoadedEstudiantes={setLoadedEstudiantes}
              setMostrarNuevo={setMostrarNuevo}
              setDialogoOK={setDialogoOK}
              setMuestraDialogoOK={setMuestraDialogoOK}
              setRecargar={setRecargar}
              habitaciones={loadedHabitaciones}
            />
          )}
          {mostrarNuevo && <Backdrop />}
          {muestraDialogoOK && (
            <OKModal texto={dialogoOK.texto} oculta={palomeaOK} />
          )}
          {muestraDialogoOK && <Backdrop zindex={49} />}
          {muestraListaNegra && (
            <ListaNegra
              nivel={props.nivel}
              setMuestraListaNegra={setMuestraListaNegra}
            />
          )}
          {muestraListaNegra && <Backdrop />}
        </section>
      );
    }
  } else {
    return (
      <section>
        <h1>Primero selecciona la casa</h1>
      </section>
    );
  }
}

export default TodosLosEstudiantesPage;
