import classes from "./TodosLosEgresos.module.css";
import { useEffect, useState, useRef } from "react";
import Backdrop from "../components/layout/backdrop";
import { FechaParaInputDate, YYYYMMDDdeDate } from "../Funciones/Funciones";
import { addMonths, addDays } from "date-fns";
import EgresosLista from "../components/egresos/EgresosLista";
import MenuFiltraEgresos from "../components/egresos/EgresosFiltra";
import MenuOrdenaEgresos from "../components/egresos/EgresosOrdena";
import NuevoEgreso from "../components/egresos/EgresoNuevo";
import EditaEgreso from "../components/egresos/EgresosEdita";
import NuevoCeCo from "../components/egresos/CeCoNuevo";
import NuevoProv from "../components/egresos/ProveedorNuevo";
import SubMenuEgresos from "../components/egresos/SubMenuEgresos";
import EditaCeCo from "../components/egresos/CeCoEdita";
import OKModal from "../components/dialogos/dialogoOK";
import PagosProgramados from "../components/recurrentes/RecurrentesProgramados";

function TodosLosEgresosPage(props) {
  const [egresosLoaded, setEgresosLoaded] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [muestraFiltroEgr, setMuestraFiltroEgr] = useState(false);
  const [muestraOrdenaEgr, setMuestraOrdenaEgr] = useState(false);
  const [posFiltroEgresos, setPosFiltroEgresos] = useState([]);
  const [ordenaEgresos, setOrdenaEgresos] = useState({
    fechaAZ: false,
    fechaZA: true,
    montoAZ: false,
    montoZA: false,
    ceCoAZ: false,
    ceCoZA: false,
    proveedorAZ: false,
    proveedorZA: false,
  });
  const [filtroEgresos, setFiltroEgresos] = useState({
    Efectivo: true,
    Banco: true,
    CeCo: "",
    Proveedor: "",
    Concepto: "",
    Observaciones: "",
    FechaIni: FechaInicio(new Date(), "carga"),
    FechaFin: FechaFin(new Date(), "carga"),
  });
  const [muestraNuevoEgreso, setMuestraNuevoEgreso] = useState(false);
  const [muestraPagosProgramados, setMuestraPagosProgramados] = useState(false);
  const [recargar, setRecargar] = useState(false);
  const [muestraEdicionEgreso, setMuestraEdicionEgreso] = useState(false);
  const [datosEdicionEgreso, setDatosEdicionEgreso] = useState([]);
  const [muestraNuevoCeCo, setMuestraNuevoCeCo] = useState(false);
  const [muestraNuevoProv, setMuestraNuevoProv] = useState(false);
  const [muestraSubMenu, setMuestraSubMenu] = useState(false);
  const [datosSubMenu, setDatosSubMenu] = useState({});
  const [muestraEditaCeCo, setMuestraEditaCeCo] = useState(false);
  const [muestraEditaForP, setMuestraEditaForP] = useState(false);
  const [muestraEditaProv, setMuestraEditaProv] = useState(false);
  const fechaIniRef = useRef();
  const fechaFinRef = useRef();
  const [dialogoOK, setDialogoOK] = useState({
    texto: "",
    mostrandoError: false,
    recargar: false,
    cerrarVentana: "",
  });
  const [muestraDialogoOK, setMuestraDialogoOK] = useState(false);

  function palomeaOK() {
    setMuestraDialogoOK(false);
    if (!dialogoOK.mostrandoError) {
      if (dialogoOK.cerrarVentana === "EditaCeCo") setMuestraEditaCeCo(false);
      if (dialogoOK.cerrarVentana === "NuevoCeCo") setMuestraNuevoCeCo(false);
      if (dialogoOK.cerrarVentana === "EdicionEgreso")
        setMuestraEdicionEgreso(false);
      if (dialogoOK.cerrarVentana === "NuevoProv") setMuestraNuevoProv(false);
      if (dialogoOK.cerrarVentana === "NuevoEgreso")
        setMuestraNuevoEgreso(false);
      if (dialogoOK.recargar) {
        setRecargar(true);
        setDialogoOK({ recargar: false });
      }
    }
  }

  function subMenuClickHandler(ev) {
    setDatosSubMenu({
      x: ev.pageX,
      y: ev.pageY,
    });
    setMuestraSubMenu(true);
  }

  function editaEgreso(IDEgr) {
    const egreso = egresosLoaded.filter((egr) => egr.IDEgr === IDEgr);
    setDatosEdicionEgreso(egreso[0]);
    setMuestraEdicionEgreso(true);
  }

  function cambioFecha() {
    const nvoFiltro = {
      ...filtroEgresos,
      ...{
        FechaIni: YYYYMMDDdeDate(
          new Date(fechaIniRef.current.value + " 0:0:0")
        ),
        FechaFin: YYYYMMDDdeDate(
          new Date(fechaFinRef.current.value + " 0:0:0")
        ),
      },
    };
    //console.log("nvofiltro", nvoFiltro);
    setFiltroEgresos(nvoFiltro);
  }

  function EgresoCompletado() {
    setMuestraNuevoEgreso(false);
    setRecargar(true);
  }

  function FechaInicio(fecha, tipo) {
    if (tipo === "carga") {
      fecha = addDays(fecha, -(fecha.getDate() - 1));
    }
    var temp = fecha.getFullYear().toString();
    temp =
      temp +
      ((fecha.getMonth() + 1).toString().length === 1
        ? "0" + (fecha.getMonth() + 1).toString()
        : (fecha.getMonth() + 1).toString());
    temp =
      temp +
      (fecha.getDate().toString().length === 1
        ? "0" + fecha.getDate().toString()
        : fecha.getDate().toString());
    //console.log("tempi", temp);
    return temp;
  }
  function FechaFin(fecha, tipo) {
    if (tipo === "carga") {
      fecha = addMonths(fecha, 1);
      fecha = addDays(fecha, -fecha.getDate());
    }
    var temp = fecha.getFullYear().toString();
    temp =
      temp +
      ((fecha.getMonth() + 1).toString().length === 1
        ? "0" + (fecha.getMonth() + 1).toString()
        : (fecha.getMonth() + 1).toString());
    temp =
      temp +
      (fecha.getDate().toString().length === 1
        ? "0" + fecha.getDate().toString()
        : fecha.getDate().toString());
    return temp;
  }

  function OrdenaEgresos(ev) {
    setPosFiltroEgresos({
      x: ev.pageX,
      y: ev.pageY,
    });
    setMuestraOrdenaEgr(true);
  }

  function reOrdenaEgresos(a, b) {
    if (ordenaEgresos.fechaAZ) return a.FechaPag > b.FechaPag ? 1 : -1;
    if (ordenaEgresos.fechaZA) return a.FechaPag < b.FechaPag ? 1 : -1;
    if (ordenaEgresos.montoAZ)
      return parseFloat(a.Monto) > parseFloat(b.Monto) ? 1 : -1;
    if (ordenaEgresos.montoZA)
      return parseFloat(a.Monto) < parseFloat(b.Monto) ? 1 : -1;
    if (ordenaEgresos.ceCoAZ) return a.CeCo > b.CeCo ? 1 : -1;
    if (ordenaEgresos.ceCoZA) return a.CeCo < b.CeCo ? 1 : -1;
    if (ordenaEgresos.proveedorAZ) return a.Proveedor > b.Proveedor ? 1 : -1;
    if (ordenaEgresos.proveedorZA) return a.Proveedor < b.Proveedor ? 1 : -1;
  }

  function FiltraEgresos(ev) {
    setPosFiltroEgresos({
      x: ev.pageX,
      y: ev.pageY,
    });
    setMuestraFiltroEgr(true);
  }

  function ExportaExcel() {
    fetch("https://www.copilco.net/inc/funciones.React.php", {
      method: "POST",
      body: JSON.stringify({
        accion: "ExportaEgresos",
        IDCasa: props.casaSel,
        FechaIni: filtroEgresos.FechaIni,
        FechaFin: filtroEgresos.FechaFin,
      }),
      headers: {
        "content-type": "application/json",
      },
    })
      .then((response) => {
        return response.blob();
      })
      .then((data) => {
        console.log(data, data.ERROR);
        const url = window.URL.createObjectURL(new Blob([data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", `Egresos.xlsx`);
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      });
  }

  function queEgresosMostrar(egreso) {
    if (egreso.CeCo.toUpperCase().indexOf(filtroEgresos.CeCo.toUpperCase()) < 0)
      return false;
    if (
      egreso.Proveedor.toUpperCase().indexOf(
        filtroEgresos.Proveedor.toUpperCase()
      ) < 0
    )
      return false;
    if (
      egreso.Concepto.toUpperCase().indexOf(
        filtroEgresos.Concepto.toUpperCase()
      ) < 0
    )
      return false;
    if (
      egreso.Observaciones.toUpperCase().indexOf(
        filtroEgresos.Observaciones.toUpperCase()
      ) < 0
    )
      return false;
    if (
      egreso.FormaPago.toUpperCase() === "EFECTIVO" &&
      !filtroEgresos.Efectivo
    )
      return false;
    if (egreso.FormaPago.toUpperCase() !== "EFECTIVO" && !filtroEgresos.Banco)
      return false;
    return true;
  }

  useEffect(() => {
    setMuestraDialogoOK(false);
    setMuestraEdicionEgreso(false);
    setMuestraEditaCeCo(false);
    setMuestraEditaForP(false);
    setMuestraEditaProv(false);
    setMuestraFiltroEgr(false);
    setMuestraNuevoCeCo(false);
    setMuestraNuevoEgreso(false);
    setMuestraNuevoProv(false);
    setMuestraOrdenaEgr(false);
    setMuestraSubMenu(false);
    if (props.casaSel > 0) {
      setRecargar(false);
      setIsLoading(true);
      fetch("https://www.copilco.net/inc/funciones.React.php", {
        method: "POST",
        body: JSON.stringify({
          accion: "ListadoEgresos",
          IDCasa: props.casaSel,
          FechaIni: filtroEgresos.FechaIni,
          FechaFin: filtroEgresos.FechaFin,
        }),
        headers: {
          "content-type": "application/json",
        },
      })
        .then((response) => {
          return response.json();
        })
        .then((data) => {
          //console.log("data", data);
          var formasPago = [];
          var ceCos = [];
          var proveedores = [];
          var egresos = [];
          if (data.ERROR !== "SI") {
            //console.log("data", data);
            for (const key in data) {
              const datos = {
                ...data[key],
              };
              if (key === "FormasPago") {
                formasPago = Object.keys(datos).map((key) => datos[key]);
              } else if (key === "CeCos") {
                ceCos = Object.keys(datos).map((key) => datos[key]);
              } else if (key === "Egresos") {
                egresos = Object.keys(datos).map((key) => datos[key]);
              } else if (key === "Proveedores") {
                proveedores = Object.keys(datos).map((key) => datos[key]);
              }
            }
            //console.log("formasPago", formasPago);
            //console.log("habitaciones", habitaciones);
            //console.log("estudiantes", estudiantes);
            //console.log("egresos", egresos);
            try {
              const egt = egresos.map((egrt1) => {
                //console.log("ingt1", ingt1);
                return {
                  Concepto: egrt1.Concepto,
                  FechaPag: egrt1.FechaPag,
                  IDCeCo: egrt1.IDCeCo,
                  IDEgr: egrt1.IDEgr,
                  IDForPag: egrt1.IDForPag,
                  IDProv: egrt1.IDProv,
                  Monto: egrt1.Monto,
                  Observaciones: egrt1.Observaciones,
                  FormaPago: formasPago.filter(
                    (formaPago) => formaPago.IDForPag === egrt1.IDForPag
                  )[0].FormaPago,
                  CeCo: ceCos.filter((ceCo) => ceCo.IDCeCo === egrt1.IDCeCo)[0]
                    .CeCo,
                  Proveedor: proveedores.filter(
                    (prov) => prov.IDProv === egrt1.IDProv
                  )[0].Proveedor,
                };
              });
              //console.log("egresos", egt);
              //setFormasPagoLoaded(formasPago);
              //setHabitacionesLoaded(habitaciones);
              setEgresosLoaded(egt);
            } catch (e) {
              setDialogoOK({
                texto: "Error al cargar egresos: " + e,
                mostrandoError: true,
                recargar: false,
                cerrarVentana: "",
              });
              setMuestraDialogoOK(true);
              console.log(e);
            }

            //setEstudiantesLoaded(estudiantes);
            setIsLoading(false);
          } else {
            setIsLoading(false);
            console.log("ERROR", data.DESCERROR);
            if (data.DESCERROR === "No se encontraron egresos") {
              setIsLoading(false);
              egresos = [];
              setEgresosLoaded(egresos);
            }
          }
        });
    } else {
      setIsLoading(false);
    }
  }, [props.casaSel, filtroEgresos.FechaFin, filtroEgresos.FechaIni, recargar]);
  //console.log("propsEgresos", props);

  /***************************************************************************/
  /************************* RENDER ******************************************/
  /***************************************************************************/
  if (isLoading) {
    //console.log("cargando");
    return (
      <section>
        <h1>Loading...</h1>
      </section>
    );
  }
  return (
    <section className={classes.centrarElem}>
      <div className={classes.TituloBoton}>
        <div className="TitEst">
          <h1>Egresos</h1>
          <label className={classes.rangoFechas}>
            <input
              required
              type="date"
              ref={fechaIniRef}
              defaultValue={FechaParaInputDate(filtroEgresos.FechaIni)}
              onChange={cambioFecha}
            ></input>{" "}
            al{" "}
            <input
              required
              type="date"
              ref={fechaFinRef}
              defaultValue={FechaParaInputDate(filtroEgresos.FechaFin)}
              onChange={cambioFecha}
            ></input>
          </label>
        </div>
        <div className="contBotones">
          {egresosLoaded.length > 0 ? (
            <button className="botAgregaEst" onClick={OrdenaEgresos}>
              <i className="fas fa-sort-amount-up-alt"></i>
            </button>
          ) : null}
          <button className="botAgregaEst" onClick={FiltraEgresos}>
            <i className="fas fa-filter"></i>
          </button>
          <button className="botAgregaEst" onClick={ExportaExcel}>
            <i className="fas fa-file-excel"></i>
          </button>
          {props.nivel === 1 ? (
            <button className="botAgregaEst" onClick={setMuestraNuevoEgreso}>
              <i className="fas fa-plus-square"></i>
            </button>
          ) : null}
          <button className="botAgregaEst" onClick={subMenuClickHandler}>
            <i className="fas fa-ellipsis-h"></i>
          </button>
        </div>
      </div>
      <EgresosLista
        editaEgreso={editaEgreso}
        setRecargar={setRecargar}
        egresosLoaded={egresosLoaded
          .sort((a, b) => reOrdenaEgresos(a, b))
          .filter((egreso) => queEgresosMostrar(egreso))}
        setDialogoOK={setDialogoOK}
        setMuestraDialogoOK={setMuestraDialogoOK}
        nivel={props.nivel}
      />
      {muestraFiltroEgr && (
        <MenuFiltraEgresos
          posicionFiltroEgresos={posFiltroEgresos}
          setFiltroEgresos={setFiltroEgresos}
          filtroEgresos={filtroEgresos}
          setMuestraFiltroEgr={setMuestraFiltroEgr}
        />
      )}
      {muestraOrdenaEgr && (
        <MenuOrdenaEgresos
          posicionOrdenaEgresos={posFiltroEgresos}
          setOrdenaEgresos={setOrdenaEgresos}
          ordenaEgresos={ordenaEgresos}
          setMuestraOrdenaEgr={setMuestraOrdenaEgr}
        />
      )}
      {muestraEdicionEgreso && (
        <EditaEgreso
          EgresoCompletado={EgresoCompletado}
          setMostrarEdicion={setMuestraEdicionEgreso}
          datosEdicionEgreso={datosEdicionEgreso}
          setRecargar={setRecargar}
          setDialogoOK={setDialogoOK}
          setMuestraDialogoOK={setMuestraDialogoOK}
          nivel={props.nivel}
        />
      )}
      {muestraNuevoEgreso && (
        <NuevoEgreso
          setMostrarNuevo={setMuestraNuevoEgreso}
          setMuestraNuevoCeCo={setMuestraNuevoCeCo}
          setMuestraNuevoProv={setMuestraNuevoProv}
          tipo={"Egreso"}
          setRecargar={setRecargar}
          setDialogoOK={setDialogoOK}
          setMuestraDialogoOK={setMuestraDialogoOK}
        />
      )}
      {muestraNuevoCeCo && (
        <NuevoCeCo
          setDialogoOK={setDialogoOK}
          setMuestraDialogoOK={setMuestraDialogoOK}
          setMuestraNuevoCeCo={setMuestraNuevoCeCo}
          setRecargar={setRecargar}
          nivel={props.nivel}
        />
      )}
      {muestraNuevoProv && (
        <NuevoProv
          setMuestraNuevoProv={setMuestraNuevoProv}
          setRecargar={setRecargar}
          setDialogoOK={setDialogoOK}
          setMuestraDialogoOK={setMuestraDialogoOK}
          nivel={props.nivel}
        />
      )}
      {muestraSubMenu && (
        <SubMenuEgresos
          setMuestraSubMenu={setMuestraSubMenu}
          datosSubMenu={datosSubMenu}
          setMuestraPagosProgramados={setMuestraPagosProgramados}
          setMuestraEditaCeCo={setMuestraEditaCeCo}
          setMuestraEditaProv={setMuestraEditaProv}
          setMuestraEditaForP={setMuestraEditaForP}
          nivel={props.nivel}
        />
      )}
      {muestraEdicionEgreso && <Backdrop />}
      {muestraEditaCeCo && (
        <EditaCeCo
          setDialogoOK={setDialogoOK}
          setMuestraDialogoOK={setMuestraDialogoOK}
          setMuestraEditaCeCo={setMuestraEditaCeCo}
          nivel={props.nivel}
        />
      )}
      {muestraPagosProgramados && (
        <PagosProgramados
          setMuestraPagosProgramados={setMuestraPagosProgramados}
          setRecargar={setRecargar}
          nivel={props.nivel}
          tipo={"Catalogo"}
        />
      )}
      {muestraEditaCeCo && <Backdrop recarga={setMuestraEditaCeCo} />}
      {muestraSubMenu && <Backdrop recarga={setMuestraSubMenu} />}
      {(muestraNuevoCeCo || muestraNuevoProv) && <Backdrop zindex={11} />}
      {muestraFiltroEgr && <Backdrop recarga={setMuestraFiltroEgr} />}
      {muestraOrdenaEgr && <Backdrop recarga={setMuestraOrdenaEgr} />}
      {muestraNuevoEgreso && <Backdrop />}
      {muestraPagosProgramados && <Backdrop />}
      {muestraDialogoOK && (
        <OKModal texto={dialogoOK.texto} oculta={palomeaOK} />
      )}
      {muestraDialogoOK && <Backdrop zindex={49} />}
    </section>
  );
}

export default TodosLosEgresosPage;
